 // funcao que trata o endereco
 export function trataEndereco(json:any){

    // cria json endreço
    let jsonEndereco = {
        numero: null,
        rua: null,
        bairro: null,
        cidade: null,
        estado: null,
        uf: null,
        pais: null,
        codigoPais: null,
        cep: null
    }
    
    // percorre o json com os dados do google
    for (let a in json){

        switch(json[a].types[0]){
            
            case 'street_number':
                // define o numero 
                jsonEndereco.numero = json[a].long_name
                break;

            case 'route':
                // define a rua 
                jsonEndereco.rua = json[a].long_name
                break;

            case 'political':
                // define o bairro 
                jsonEndereco.bairro = json[a].long_name
                break;

            case 'administrative_area_level_2':
                // define a cidade 
                jsonEndereco.cidade = json[a].long_name
                break;

            case 'administrative_area_level_1':
                // define o estado 
                jsonEndereco.estado = json[a].long_name
                jsonEndereco.uf = json[a].short_name
                break;

            case 'country':
                // define o pais 
                jsonEndereco.pais = json[a].long_name
                jsonEndereco.codigoPais = json[a].short_name
                break;

            case 'postal_code':
                // define o cep 
                jsonEndereco.cep = json[a].long_name
                break;


            default:
                break;

        }

    }

   return jsonEndereco 

}

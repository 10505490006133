export function funcaoTrataTempo(tempo:string, mostraSufixo:boolean=true){

    // quebra o tempo em uma array
    let array = tempo.split(':');
    
    // verifica se possui horas
    if(array[0] === "00"){

        // somente minutos
        mostraSufixo
            ? tempo = `${array[1]} min`
            : tempo = `${array[1]}`;
       
    }else{

        // horas
        mostraSufixo
            ? tempo = `${array[0]}:${array[1]} hs`
            : tempo = `${array[0]}:${array[1]}`
        ;
    }

    // retorno da funcao
    return tempo;

}
import axios from 'axios';

// define
axios.defaults.baseURL = 'https://www.bemquetevi.com.br/api';
// axios.defaults.baseURL = 'http://localhost:81';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// retorna configurações axios
export default function AxiosConfig(){
   
    return null;

}
import { ThemeProvider } from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react'
import AxiosConfig from './AxiosConfig';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/locale/pt-br';

import './App.css';
import tema from "./global/styles/tema";
import Rotas from "./Rotas";
import { ContextoLoginProvider } from './Contextos/Contexto';

export default function App() { 

  // define moment como pt-br
  moment.locale('pt-br');

  return(
    <ThemeProvider theme={tema}>
      <ChakraProvider>
        <ContextoLoginProvider>        
            <AxiosConfig />             
            <Rotas />          
        </ContextoLoginProvider>
      </ChakraProvider>
    </ThemeProvider>
  )
}
import { Box, } from '@chakra-ui/layout';
import { Route, Routes } from "react-router-dom";
import GoogleMap from 'google-map-react';

import Carregamento from '../../componentes/Carregamento/index';
import QuilometragemVeiculos from '../QuilometragemVeiculos';
import EstatisticaVeiculo from '../EstatisticaVeiculo';
import MinhasViagens from '../MinhasViagens';
import Notificacoes from '../Notificacoes';
import ViagemMapa from '../ViagemMapa';
import LocalizacaoAtual from '../LocalizacaoAtual';
import VeiculosMapa from '../VeiculosMapa';
import CadastroVeiculo from '../CadastroVeiculo';
import Home from '../Home';
import EdicaoUsuario from '../EdicaoUsuario';
import { useContexto } from '../../Contextos/Contexto';

export default function RotasHome() {

    const { carregamento, usuario } = useContexto();    
    
    return(<>
        <GoogleMap                        
            yesIWantToUseGoogleMapApiInternals            
            defaultZoom={10}
            defaultCenter={{
                lat: parseFloat(usuario.latitude),
                lng: parseFloat(usuario.longitude)
            }}
            bootstrapURLKeys={{
                key: usuario.chaveApiGoogle,
                language: 'pt-br',
                region: 'BR'                  
            }}
            options={{
                scrollwheel: false,
                fullscreenControl: false,
                zoomControl: false
            }}  
            style={{
                zIndex: 1,
                opacity: 0.5
            }}
        />  
        <Box direction={'column'} bg={'transparent'} w={'100%'} h={'100%'} pos={'absolute'}>
            <Carregamento mostrar={carregamento.mostrar} mensagem={carregamento.mensagem} />
            <Routes>
                <Route 
                    // index
                    path={'/'} 
                    element={<Home />}
                /> 
                {/* <Route 
                    path={'/usuario'}
                    element={<EdicaoUsuario />}
                /> */}
                {/* <Route 
                    path={'/veiculo-quilometragem'} 
                    element={<QuilometragemVeiculos />} 
                />
                */}
                <Route 
                    path={'/veiculo-quilometragem/estatistica/:id'} 
                    element={<EstatisticaVeiculo />} 
                /> 
                <Route 
                    path={'/veiculos-mapa'} 
                    element={<VeiculosMapa />} 
                /> 
                <Route 
                    path={'/viagens/:id'} 
                    element={<MinhasViagens />} 
                />
                <Route 
                    path={'/viagens/:id/notificacoes'} 
                    element={<Notificacoes />} 
                />
                <Route 
                    path={'/viagens/:id/mapa'} 
                    element={<ViagemMapa />} 
                /> 
                <Route 
                    path={'/viagens/:id/localizacao-atual'} 
                    element={<LocalizacaoAtual />} 
                />
                <Route 
                    path={'/cadastro-veiculo'} 
                    element={<CadastroVeiculo />} 
                />                    
            </Routes>                                       
        </Box>         

    </>)

}
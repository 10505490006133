import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faMotorcycle, faAmbulance, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import Icon from '@mdi/react'
import { mdiFireTruck } from '@mdi/js';
import { 
    Box, 
    Flex,
    Center, 
    Tooltip, 
    Container, 
    VStack, 
    Checkbox,
    Text,
    HStack,
    Divider
} from '@chakra-ui/react';
import io from 'socket.io-client';

import GoogleMap from 'google-map-react';
import axios from "axios";

import Topbar from '../../componentes/Topbar';
import ModalConfirmacao from '../../componentes/ModalConfirmacao';
import buscaEndereco from '../../funcoes/buscaEndereco';
import { useContexto } from '../../Contextos/Contexto';
import { isMobile } from 'react-device-detect';

interface LocalizacaoProps {    
    idr: number;
    lat: number;
    lng: number;    
    md: number;    
    tm: number;
    tp: number;
    id: string;
    mod: string;
    mar: string;    
}

interface VeiculosProps {
    desligados: number;
    inativos: number;
    ligados: number;
}

interface ModalProps {
    mostrar: boolean;
    texto: string;
}

interface RetornoRequisicaoProps {
    data: { 
        veiculos: VeiculosProps; 
        localizacoes: LocalizacaoProps[] 
    }
}

interface RetornoSocketProps {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
    10: string;
    11: string;
    12: string;
    13: string;
    14: string;
    15: string;
    16: string;
    17: string;
    18: string;
    19: string;
    20: string;
}

interface RetornoSocketNt20Props {
    alarm: number;
    batteryVoltage: number;
    cellId: number;
    courseStatus: number;
    dataTimeGps: number;
    gsm: number;
    internalDateTime: number;
    lac: number;
    latitude: number;
    lbs: number;
    locationSourceType: number;
    longitude: number;
    mcc: number;
    mileage: number;
    mnc: number;
    powerVoltage: number;
    quantitySatellites: number;
    serialNumber: number;
    speed: number;
    terminalId: string;
    totalHoursSum: number;
    terminalInformationContent: {
        bit1: string;
    }
}

export default function VeiculosMapa() {

    // resgata valores do contexto
    const { alterarCarregamento, usuario } = useContexto();

    // hooks  
    const parametros = useLocation();     
    const theme = useTheme();
    const refMapa = useRef<any>(null);    
    const socket = useRef<any>(null);
    const navigate = useNavigate(); 

    // estados
    const [ status, alterarStatus ] = useState(false);
    const [ consultou, alterarConsultou ] = useState(false);
    const [ mostrarLegendas, alterarMostrarLegendas ] = useState(false);
    const [ modal, alterarModal ] = useState({} as ModalProps);
    const [ veiculoSelecionado, alterarVeiculoSelecionado ] = useState<LocalizacaoProps | null>(null);
    const [ localizacoes, alterarLocalizacoes ] = useState<LocalizacaoProps[]>([]);    
    const [ veiculos, alterarVeiculos ] = useState<VeiculosProps[]>([]);
    const [ retornos, alterarRetornos ] = useState<RetornoSocketProps[]>([]);
    const [ retornosNt20, alterarRetornosNt20 ] = useState<RetornoSocketNt20Props[]>([]);
    const [ zoomMapa, alterarZoomMapa ] = useState(12);

    // função responsável por buscar a localização de todos os veículos no mapa
    async function buscaLocalizacaoTodos() {

        // mostra carregamento
        if(!consultou) {
            alterarCarregamento({ mostrar: true, mensagem: 'Inserindo veículos no mapa...' });
            alterarConsultou(true);
        }

        // prepara veiculos
        let veiculosAtivos: LocalizacaoProps[] = [];
        let veiculosDesligados: LocalizacaoProps[] = [];
        let veiculosInativos: LocalizacaoProps[] = [];
    
        try {

            // faz a requisição
            const { data }: RetornoRequisicaoProps = await axios({
                url: '/veiculos/buscaLocalizacaoTodos.php',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                    id_principal: usuario.id_principal
                }
            });            
            
            // armazena informações
            const informacoes: any[] = [
                {
                    ligados: data.veiculos.ligados
                },
                {
                    desligados: data.veiculos.desligados
                },
                {
                    inativos: data.veiculos.inativos
                }
            ];

            // filtra os arrays para ordernar por ativos
            veiculosAtivos = data.localizacoes.filter(loc => loc.md == 2);                    
            veiculosDesligados = data.localizacoes.filter(loc => loc.md == 1 && loc.tm == 0);
            veiculosInativos = data.localizacoes.filter(loc => loc.md == 1 && loc.tm > 0);

            // altera os dados da localização
            alterarLocalizacoes(veiculosAtivos.concat(veiculosDesligados).concat(veiculosInativos));

            // altera informações dos veiculos
            alterarVeiculos(informacoes);               

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });

        }

    }    

    // função para verificar que tipo de marcador é para ser inserido no mapa
    function verificaMarcadorMapa(tipo: number, modeloParametro: string): JSX.Element {

        // verifica modelo
        const modelo = typeof modeloParametro !== 'string' ? '' : modeloParametro.trim();

        // prepara marcador, setando carro como padrão
        let marcador = faCar;

        // se for ambulancia
        if(modelo == 'Ambulância' && tipo == 1) marcador = faAmbulance;        

        // se for caminhão
        if((modelo == 'Caminhão' && tipo == 3) || tipo == 3) return <Icon 
            path={mdiFireTruck}     
            style={{
                width: '100%',
                height: '100%'
            }} 
            color={theme.cores.texto}                                  
        />;

        // verifica o tipo
        switch(tipo) {

            case 1:
                if(modelo !== 'Ambulância') marcador = faCar;      
                break;
            case 2:
                marcador = faMotorcycle;
                break;
                      
        }

        // retorna o ícone com seu path
        return <FontAwesomeIcon 
            icon={marcador}
            color={theme.cores.texto}
            style={{
                width: '100%',
                height:'100%'
            }}
        />
        
    }

    // função responsável por atualizar o veículo no mapa
    function atualizarPosicaoVeiculo(dados: RetornoSocketProps) {
        
        // armazena os dados vindos do socket
        alterarRetornos([...retornos, dados]);

        // prepara veiculos
        let veiculosAtivos: LocalizacaoProps[] = [];
        let veiculosDesligados: LocalizacaoProps[] = [];
        let veiculosInativos: LocalizacaoProps[] = [];
        
        // prepara coordenadas
        let latitudade: number = Number(dados[7]);
        let longitude: number = Number(dados[8]);

        // verifica se possui coordenadas
        if(latitudade && longitude){                    

            // resgata novo array de localizações
            let novoArrayLocalizacoes = localizacoes.filter(local => local.id !== dados[1]);

            // // resgata veículo para atualizar posição
            let veiculoAtualizar = localizacoes.find(local => local.id == dados[1]);                        
            
            // // se achou
            if(veiculoAtualizar) {
                
                // atualiza coordenadas
                veiculoAtualizar = {
                    ...veiculoAtualizar,
                    lat: latitudade,
                    lng: longitude
                }

                // concatena array antigo com objeto novo
                let arrayLocalizacoesAtualizar = novoArrayLocalizacoes.concat(veiculoAtualizar);

                // filtra os arrays para ordernar por ativos
                veiculosAtivos = arrayLocalizacoesAtualizar.filter(loc => loc.md == 2);                    
                veiculosDesligados = arrayLocalizacoesAtualizar.filter(loc => loc.md == 1 && loc.tm == 0);
                veiculosInativos = arrayLocalizacoesAtualizar.filter(loc => loc.md == 1 && loc.tm > 0);
                
                // altera localização
                alterarLocalizacoes(veiculosAtivos.concat(veiculosDesligados).concat(veiculosInativos));

                // verifica se atualiza veiculo atualizado
                if(veiculoSelecionado && veiculoAtualizar.id === veiculoSelecionado.id) {

                    // altera coordenadas do veiculo selecionado
                    alterarVeiculoSelecionado({
                        ...veiculoSelecionado,
                        lat: veiculoAtualizar.lat,
                        lng: veiculoAtualizar.lng
                    })

                }

                // verifica se mudou de modo
                if(parseInt(dados[16]) != veiculoAtualizar.md) {
                    buscaLocalizacaoTodos();
                }

            }

        }   

    } 

    // função responsável por atualizar o veículo no mapa com rastreador novo
    function atualizarPosicaoVeiculoNt20(dados: RetornoSocketNt20Props) {
        
        // armazena os dados vindos do socket
        alterarRetornosNt20([...retornosNt20, dados]);

        // prepara veiculos
        let veiculosAtivos: LocalizacaoProps[] = [];
        let veiculosDesligados: LocalizacaoProps[] = [];
        let veiculosInativos: LocalizacaoProps[] = [];
        
        // prepara coordenadas
        let latitudade: number = Number(dados.latitude);
        let longitude: number = Number(dados.longitude);
        
        // verifica se possui coordenadas
        if(latitudade && longitude){                    

            // resgata novo array de localizações
            let novoArrayLocalizacoes = localizacoes.filter(local => parseInt(local.id) !== parseInt(dados.terminalId));

            // // resgata veículo para atualizar posição
            let veiculoAtualizar = localizacoes.find(local => parseInt(local.id) == parseInt(dados.terminalId));                        
            
            // // se achou
            if(veiculoAtualizar) {

                // prepara modo
                const modo  = dados.terminalInformationContent.bit1 == '0' ? '1':'2' ;
                
                // atualiza coordenadas
                veiculoAtualizar = {
                    ...veiculoAtualizar,
                    lat: latitudade,
                    lng: longitude
                }

                // concatena array antigo com objeto novo
                let arrayLocalizacoesAtualizar = novoArrayLocalizacoes.concat(veiculoAtualizar);

                // filtra os arrays para ordernar por ativos
                veiculosAtivos = arrayLocalizacoesAtualizar.filter(loc => loc.md == 2);                    
                veiculosDesligados = arrayLocalizacoesAtualizar.filter(loc => loc.md == 1 && loc.tm == 0);
                veiculosInativos = arrayLocalizacoesAtualizar.filter(loc => loc.md == 1 && loc.tm > 0);

                // altera localização
                alterarLocalizacoes(veiculosAtivos.concat(veiculosDesligados).concat(veiculosInativos));

                // verifica se atualiza veiculo atualizado
                if(veiculoSelecionado && veiculoAtualizar.id === veiculoSelecionado.id) {

                    // altera coordenadas do veiculo selecionado
                    alterarVeiculoSelecionado({
                        ...veiculoSelecionado,
                        lat: veiculoAtualizar.lat,
                        lng: veiculoAtualizar.lng
                    })

                }
                
                // verifica se mudou de modo
                if(parseInt(modo) !== veiculoAtualizar.md) {

                    buscaLocalizacaoTodos();

                }

            }

        }        

    }

    function criarOpcoesMapa(maps: any) {        
        
        return {
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true,
            scrollwheel: true,
            fullscreenControl: true,
            styles: [{
                "featureType": "all",
                "elementType": "labels",
                "stylers": [{
                  "visibility": "#on"
                }]
              }]
        };

    }
    
    // cria marcador
    const Marcador = ({ id, lat, lng, label, icone, ativo, bg, ...props }: any) => {
        
        useEffect(() => {
                
            // recebe um sinal de que algum usuário está online ou offline
            socket.current.on(`posicaoAtualizadaNt20/${parseInt(id)}`, atualizarPosicaoVeiculoNt20);
            
            // retorna o socket para deixar desligado
            return () => {
                socket.current.off(`posicaoAtualizadaNt20/${parseInt(id)}`, atualizarPosicaoVeiculoNt20); 
            }          
                
        }, []);

        useEffect(() => {
                            
            // recebe um sinal de que algum usuário está online ou offline
            socket.current.on(`posicaoAtualizada/${parseInt(id)}`, atualizarPosicaoVeiculo);
            
            // retorna o socket para deixar desligado
            return () => {
                socket.current.off(`posicaoAtualizada/${parseInt(id)}`, atualizarPosicaoVeiculo); 
            }        
    
        }, []);
       
        return <Tooltip defaultIsOpen={mostrarLegendas} label={label} aria-label={'descrição'}>
           
            <Center 
                w={zoomMapa > 12 ? '40px' : '25px'} 
                h={zoomMapa > 12 ? '40px' : '25px'}
                p={zoomMapa > 12 ? '5px' : '2.5px'} 
                borderWidth={'4px'} 
                borderColor={'black'}
                borderRadius={20}
                bg={bg}
                position={'absolute'}
                transform={'translate(-50%, -50%)'}
                onClick={async () => {

                    // mostra carregamento
                    alterarCarregamento({
                        mostrar: true,
                        mensagem: 'Carregando endereço'
                    })

                    // resgata texto
                    const texto = await buscaEndereco({
                        usuario, 
                        lat, 
                        lng, 
                        alterarCarregamento
                    });   

                    // esconde carregamento
                    alterarCarregamento({ mostrar: false });
                    
                    // mostra modal
                    alterarModal({
                        mostrar: true,
                        texto,
                    })

                }}
                _hover={{
                    cursor: 'pointer'
                }}
                {...props}
            >   
                {icone}
            </Center>
        </Tooltip>
        
    }

    useEffect(() => {
        // busca a localização de todos os veículos no mapa
        (async () => await buscaLocalizacaoTodos())();        
    }, []);

    // inicializa sockets
    useEffect(() => {

        // inicializa
        socket.current = io(`https://bemquetevi.com.br:7211/`, {
            transports: ['websocket'],
            secure: true
        });

        // desconectado
        return () => {

            socket.current.close();

        };
        
    }, []);

    useEffect(() => {
        
        // verifica se possui um veiculo selecionado para fazer o acompanhamento
        if(veiculoSelecionado && (refMapa && refMapa.current)) {
        
            // cria nova coordenadas
            const local = new refMapa.current.maps_.LatLng(veiculoSelecionado.lat, veiculoSelecionado.lng);           
            
            // centraliza marcador no mapa e seu zoom
            refMapa.current.map_.setZoom(16);
            refMapa.current.map_.setCenter(local);

        }

    }, [veiculoSelecionado]);

    // verifica se socket está online
    useEffect(() => {

        // define o status do socket
        socket.current.on('connect', () => alterarStatus(true));
        socket.current.on('disconnect', () => alterarStatus(false));

        // em caso de mudança do status, socket deverá ser destruido para ser reconsturido
        return () => {

            socket.current.off('connect', alterarStatus(true));
            socket.current.off('disconnect', alterarStatus(false));

        };

    }, [status, retornos, retornosNt20]);

    return(<>

        <Box flex={1} height={'100vh'} flexDirection={'column'} bg={theme.cores.fundoGeral}>
            <Topbar 
                rota={parametros.pathname} 
                navigate={navigate} 
                titulo='Meus veículos' 
            />
            <Flex flexGrow={1} h={'calc(100% - 70px)'} pos={'relative'}>                    
                <GoogleMap     
                    ref={refMapa}                        
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={{                        
                        lat: parseFloat(usuario.latitude),
                        lng: parseFloat(usuario.longitude)
                    }}
                    defaultZoom={14}
                    bootstrapURLKeys={{
                        key: usuario.chaveApiGoogle,
                        language: 'pt-br',
                        region: 'BR',
                         libraries:['places', 'geometry', 'drawing', 'visualization']
                    }}
                    onChange={(propsMapa) => alterarZoomMapa(propsMapa.zoom)}
                    options={criarOpcoesMapa}  
                >

                    {/* verifica se possui localizações */}
                    {localizacoes.length > 0 && localizacoes.map((localizacao, indice) => {                                                                        
                        return <Marcador 
                            id={localizacao.id}
                            key={localizacao.id}
                            lat={localizacao.lat}
                            lng={localizacao.lng}
                            icone={verificaMarcadorMapa(localizacao.tp, localizacao.mod)}
                            label={`${localizacao.mar || ''} ${localizacao.mod || ''}`}
                            ativo={Number(localizacao.tm) > 0 ? false : (localizacao.md === 1 ? false : true)}
                            bg={Number(localizacao.tm) > 0 ? theme.cores.cinza : (localizacao.md === 1 ? theme.cores.vermelho : theme.cores.verde)}
                        />
                    })}

                    {/* {localizacoes.length > 0 && localizacoes.map((loc, i) => {
                        return <MarcadorTeste 
                            key={i}
                            id={loc.id}
                            lat={loc.lat}
                            lng={loc.lng}
                        />
                    })} */}

                </GoogleMap>
                
                <Container  
                    pos={'absolute'}                      
                    maxW={['200px', '220px']}
                    w={['100%', '20vw']}
                    minW={'100px'}
                    top={['5px','10px']}
                    margin={'auto'}
                    left={['0px', '30px']}
                    h={'auto'}
                >
                    <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        borderRadius={10}
                        border={'1px solid black'}
                        p={'10px'}
                        bg={theme.cores.fundoBotaoTransparente}
                        w={'100%'}
                        flex={1}
                        overflow={'hidden'}
                        _focus={{
                            borderStyle: 'none',
                            textDecoration: 'none',
                            boxShadow:'none !important'
                        }}
                    >
                        <Checkbox
                            isTruncated
                            color={theme.cores.texto}
                            fontFamily={`${theme.fontes.titulo}, cursive`}
                            boxShadow={'none !important'}        
                            isChecked={mostrarLegendas}
                            onChange={() => {                                           

                                // altera para valor contrário
                                alterarMostrarLegendas(!mostrarLegendas);                                    

                            }}                                                       
                            alignItems={'center'}
                            justifyContent={'center'}                                
                            colorScheme={mostrarLegendas ? theme.cores.verde : theme.cores.texto}                                
                            _hover={{
                                opacity: 0.6,
                                cursor: 'pointer',
                                boxShadow:'none !important',
                            }}
                            _active={{
                                opacity: 0.3,
                                boxShadow:'none !important',
                                borderStyle: 'none',
                                textDecoration: 'none',                                  
                            }}
                            _focus={{
                                borderStyle: 'none',
                                textDecoration: 'none',
                                boxShadow:'none !important',                                    
                            }}
                            size={isMobile ? 'sm' : 'md'}
                            fontSize={['sm', 'md']}
                        >
                            Mostrar legendas
                        </Checkbox>
                    </Flex>
                    {(localizacoes && localizacoes.length > 0) && <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        borderRadius={10}
                        border={'1px solid black'}
                        p={'10px'}
                        bg={theme.cores.fundoBotaoTransparente}
                        textColor={theme.cores.texto}
                        w={'100%'}
                        maxH={'400px'}
                        flex={1}
                        overflow={'auto'}
                        mt={'5px'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: theme.cores.preto,
                                borderRadius: '10px',
                            }
                        }}
                    >
                        <VStack
                            flex={1}
                            w={'100%'}
                            h={'100%'}
                            align={'center'}
                            justify={'center'}
                            divider={<Divider orientation='horizontal' />}
                            
                        >
                            {localizacoes.map((loc, i) => <Flex
                                flex={1}
                                align={'center'}
                                justify={'space-between'}
                                w={'100%'}
                                margin={'auto'}
                                mt={'0.5rem'}
                                onClick={() => {

                                    // verifica se possui veiculo selecionado
                                    if(veiculoSelecionado && veiculoSelecionado.id == loc.id) {
                                        
                                        // altera veiculo selecionado para nulo
                                        alterarVeiculoSelecionado(null);

                                    } else {

                                        // cria nova coordenadas
                                        const local = new refMapa.current.maps_.LatLng(loc.lat, loc.lng);                                            

                                        // centraliza marcador no mapa e seu zoom
                                        refMapa.current.map_.setZoom(16);
                                        refMapa.current.map_.setCenter(local);

                                        // altera o veiculo seelcionado
                                        alterarVeiculoSelecionado(loc);

                                    }

                                }}
                                _hover={{
                                    opacity: 0.6,
                                    cursor: 'pointer'
                                }}
                                _active={{
                                    opacity: 0.3
                                }}
                            >
                                <Flex 
                                    w={isMobile ? '20px' : '40px'}
                                    h={isMobile ? '20px' : '40px'}
                                    align={'center'} 
                                    justify={'center'}
                                    borderRadius={50}   
                                    boxShadow={veiculoSelecionado?.id && veiculoSelecionado.id == loc.id ? '0 0 0.5em black;' : '0 0 0em'}
                                    bg={Number(loc.tm) > 0 ? theme.cores.cinza : (loc.md === 1 ? theme.cores.vermelho : theme.cores.verde)}
                                    p={'10px'}
                                >
                                    {verificaMarcadorMapa(loc.tp, loc.mod)}
                                </Flex>
                                <Text 
                                    isTruncated 
                                    color={veiculoSelecionado?.id && veiculoSelecionado.id == loc.id ? theme.cores.texto : '#adadad'}
                                    fontSize={isMobile ? 'xs' : ['sm', 'md']}
                                >
                                    {loc.mar}
                                </Text>
                            </Flex>)}
                        </VStack>
                    </Flex>}
                </Container>

            </Flex>
        </Box>

        {/* verifica se mostra modal */}
        {modal.mostrar && <ModalConfirmacao
            mostrar={modal.mostrar}
            texto={modal.texto}
            aoFechar={() => alterarModal({ ...modal, mostrar: false })}
            aoSalvar={() => {}}
            titulo={''}
        />}

    </>)

}
const tema = {
    cores: {
        topoTopBar: '#333333',
        fundoGeral: '#4A6CAD',
        fundoGeralEscuro: '#483D8B',
        texto: '#FFF',
        cinzaClaro:'#999999',
        cinza: 'gray',
        vermelho: 'red',
        verde: 'green',
        alerta: '#FFD700',
        preto: '#000',
        fundoBotaoTransparente: 'rgba(51,51,51,0.8)',
        cinzaEscuro: '#666'
    },
    fontes: {
        titulo: 'Balsamiq Sans', // cursive
        texto: 'Jost', // sans-serif
    }
}

export default tema;
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmbulance, faCar, faMotorcycle } from '@fortawesome/free-solid-svg-icons';
import { IoMdPin, IoMdAlert } from "react-icons/io";
import { Text, Divider, VStack, HStack, Flex, Stack } from '@chakra-ui/layout';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

import { useContexto } from '../../Contextos/Contexto';
import Lista from '../../componentes/Lista';
import Topbar from '../../componentes/Topbar';
import { ModalDataProps } from '../../interfaces';
import { funcaoTrataTempo } from '../../funcoes/trataTempo';
import { mdiFireTruck } from '@mdi/js';
import Icon from '@mdi/react';

export default function MinhasViagens() {

    const { usuario, alterarCarregamento } = useContexto();            
    const { id } = useParams();
    const parametros = useLocation();
    
    const theme = useTheme();          
    const navigate = useNavigate();     

    const meses = [
        'Janeiro', 
        'Fevereiro', 
        'Março', 
        'Abril', 
        'Maio', 
        'Junho', 
        'Julho', 
        'Agosto', 
        'Setembro', 
        'Outubro', 
        'Novembro', 
        'Dezembro'
    ];

    const [ pagina, alterarPagina ] = useState<number>(1);
    const [ carregouDados, alterarCarregouDados ] = useState<boolean>(false);
    const [ viagens, alterarViagens ] = useState([]); 
    const [ filtroData, alterarFiltroData ] = useState<string>('0000-00-00');
    const [ placeholderFiltroData, alterarPlaceholderFiltroData ] = useState<string>('');
    const [ modalData, alterarModalData ] = useState({
        mostrar: false,
        titulo: 'Escolha uma data disponível',
        textoBotaoSalvar: 'Confirmar'
    } as ModalDataProps);    
   
    // função que carrega os veículos no mapa
    async function carregaQuilometragem() {

        // mostra carregamento
        alterarCarregamento({ mostrar: true });   

        // instancia placeholder
        let placeholder = '';
        
        try {                                       
            
            // faz a requisição
            const retornoQuilometragem = await axios({
                url: '/viagens/buscaTotalViagens.php',
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data:{
                    id,
                    id_principal: usuario.id_principal,
                    paginaAtual: pagina,
                    quantidade: 15,
                    busca: '',
                    filtroData
                }
            })

            // verifica se veio retorno
            if(retornoQuilometragem.data.status){                                      

                // altera array
                alterarViagens(retornoQuilometragem.data.dados);
                                
            } else {
                alterarViagens([]);
                alterarPagina(1);
            }

            // verifica data
            if(filtroData === '' || filtroData === '0000-00-00') {

                // altera placeholder da data
                alterarPlaceholderFiltroData('Filtrar por data');                  

            } else {

                const divide = filtroData.split("-");
                const dia = divide[2]
                const mes = divide[1]
                const ano = divide[0]

                // verifica se possui dia
                if(dia !== '00'){
                    // intera o placeholder
                    placeholder += `${dia} de `
                }
    
                // verifica se possui mês
                if(mes !== '00'){
                    // intera o placeholder
                    placeholder += `${meses[Number(mes)-1]} de `
                }
    
                // verifica se possui ano
                if(ano !== '0000'){
                    // intera o placeholder
                    placeholder += `${ano}`
                }
                
                // altera placeholder data
                alterarPlaceholderFiltroData(placeholder);            

            }     
            
            // alterar pra ver se ja carregou todos
            alterarCarregouDados(retornoQuilometragem.data.fim);

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });
        
        }

    }    
    
    // função para verificar que tipo de marcador é para ser inserido no mapa
    function verificaMarcadorMapa(tipo: number, modelo: string): JSX.Element {

        // prepara marcador, setando carro como padrão
        let marcador = faCar;

        // se for ambulancia
        if(modelo == 'Ambulância' && tipo == 1) marcador = faAmbulance;        

        // se for caminhão
        if((modelo == 'Caminhão' && tipo == 3) || tipo == 3) return <Icon 
            path={mdiFireTruck}     
            style={{
                width: '25px',
                height: '25px'
            }} 
            color={theme.cores.texto}                                  
        />;

        // verifica o tipo
        switch(tipo) {

            case 1:
                if(modelo !== 'Ambulância') marcador = faCar;      
                break;
            case 2:
                marcador = faMotorcycle;
                break;
                      
        }

        // retorna o ícone com seu path
        return <FontAwesomeIcon 
            icon={marcador}
            color={theme.cores.texto}
            style={{
                width: '25px',
                height:'25px'
            }}
        />
        
    }

    // cria children do topbar da lista
    const ChildrenTopbarLista = () => {
        return(<Stack w={'100%'} flex={1}>
            <Stack h={'100px'} flex={1} justify={['space-between', 'center']} flexDirection={['column', 'column', 'column', 'row']}>
                <HStack flexGrow={1} align={'center'} justify={'center'}>
                    <Flex 
                        p={2} 
                        bg={
                            Number(parametros.state.time) > 0 
                            ? theme.cores.cinza : 
                            (
                                parametros.state.modo === '1' || parametros.state.modo === '2' 
                                ? (
                                    parametros.state.modo === '1' ? theme.cores.vermelho : theme.cores.verde
                                )
                                : theme.cores.cinza
                            )
                        }                             
                        borderRadius={50} 
                        align={'center'} 
                        justify={'center'}
                    >
                        {verificaMarcadorMapa(parametros.state.tipo, parametros.state.modelo)}  
                    </Flex> 
                    <VStack align={'center'} justify={'center'} spacing={'-5px'}>                       
                        <HStack 
                            align={'center'} 
                            justify={'center'}
                            onClick={() => {
                                navigate(`/home/veiculo-quilometragem/estatistica/${id}`);
                            }}
                            _hover={{
                                opacity: 0.6,
                                cursor: 'pointer'
                            }}
                            _active={{
                                opacity: 0.3
                            }}
                        >                                                           
                            <Text   
                                fontFamily={`${theme.fontes.titulo}, cursive`} 
                                color={theme.cores.texto}
                                fontSize={['xs', 'sm', 'md']}
                                textAlign={'center'}
                                isTruncated                                    
                            >
                                {parametros.state.marca || ''} {parametros.state.modelo || ''}
                            </Text>                    
                        </HStack>
                        <Text   
                            fontFamily={`${theme.fontes.texto}, sans-serif`} 
                            color={theme.cores.cinzaClaro}
                            fontSize={['xs', 'sm', 'md']}
                            isTruncated
                            textAlign={'center'}
                        >
                            Placa: {parametros.state.placa}
                        </Text>
                    </VStack>
                </HStack>
                <Flex h={['0px', '0px', '40px']}>
                    <Divider borderBottomWidth={['1px', '1px', '0px']} borderLeftWidth={['0px', '0px', '1px']} w={['100%', '100%', '0px']} h={['0px', '0px', '100%']} />
                </Flex>  
                <Flex 
                    flexGrow={1} 
                    mt={['auto', 'auto', '0px !important']}
                    align={'center'}                     
                    justify={'center'} 
                    gridGap={2}
                    onClick={() => {
                        navigate(`localizacao-atual`, {
                            state: parametros.state
                        });
                    }}
                    _hover={{
                        opacity: 0.6,
                        cursor: 'pointer'
                    }}
                    _active={{
                        opacity: 0.3
                    }}
                >
                    <IoMdPin 
                        size={20}               
                        color={theme.cores.texto} 
                    />             
                    <Text 
                        marginBottom={-1}                                     
                        fontFamily={`${theme.fontes.titulo}, cursive`} 
                        color={theme.cores.texto}
                        fontSize={['xs', 'sm', 'md']}
                        textAlign={'center'}
                        flexDirection={'row'}                       
                        isTruncated
                    >
                        Localização Atual
                    </Text>
                </Flex>
                <Flex h={['0px', '0px', '40px']}>
                    <Divider borderBottomWidth={['1px', '1px', '0px']} borderLeftWidth={['0px', '0px', '1px']} w={['100%', '100%', '0px']} h={['0px', '0px', '100%']} />
                </Flex>  
                <Flex 
                    flexGrow={1} 
                    mt={['auto', 'auto', '0px !important']}
                    align={'center'} 
                    justify={'center'} 
                    gridGap={2}
                    _hover={{
                        opacity: 0.6,
                        cursor: 'pointer'
                    }}
                    _active={{
                        opacity: 0.3
                    }}
                    onClick={() => {
                        navigate(`notificacoes`, {
                            state: parametros.state
                        });
                    }}
                >
                    <IoMdAlert 
                        size={20}
                        color={theme.cores.texto}
                    />                        
                    <Text 
                        marginBottom={-1}                                     
                        fontFamily={`${theme.fontes.titulo}, cursive`} 
                        color={theme.cores.texto}
                        fontSize={['xs', 'sm', 'md']}
                        textAlign={'center'}
                        flexDirection={'row'}                       
                        isTruncated
                    >
                        Alertas e emergências
                    </Text> 
                </Flex>
            </Stack>    
        </Stack>)
    }   

    useEffect(() => {
        // carrega função novamente
        carregaQuilometragem();
    }, [pagina, filtroData])
    
    return(<Flex w={'100%'} h={'100%'} direction={'column'} bg={'transparent'}>
        <Topbar rota={parametros.pathname} titulo={'Minhas Viagens'} navigate={navigate} />             
        <Lista 
            pagina={pagina}
            modalData={modalData}
            dados={viagens}
            placeholderFiltro={placeholderFiltroData}
            alterarModalData={alterarModalData}
            alterarPagina={alterarPagina}            
            alterarFiltroData={alterarFiltroData}        
            childTopbarLista={<ChildrenTopbarLista />}
            registroClicado={(viagem) => {
                navigate(`mapa`, {
                    state: {
                        viagem,
                        veiculo: parametros.state
                    }
                });
            }}
            estiloContainerLista={{
                maxHeight: 550
            }}
            estiloContainer={{
                p: '10px'
            }}            
            tipoLista={'viagem'}     
            textoPrincipal={(dado) => `${dado.ds} km (${funcaoTrataTempo(dado.dr)})`}
            textoSecundario={(dado) => `${dado.vg} ${dado.vg > 0 ? 'viagens' : 'viagem'}`}  
            labelTooltip={(dado) =>  moment(dado.dt).format('DD/MM/YYYY')}            
        />
    </Flex>) 

}
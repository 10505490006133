import { useEffect, useState } from 'react' 
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, useToast, Image, Text } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect';
import axios from 'axios';

import logo from '../../assets/imagens/logo.png';
import './loginEstilo.scss';
import { useContexto } from '../../Contextos/Contexto';
import { useTheme } from 'styled-components';

// componente
export default function Login(){
        
    const [ usuarioLogin, alterarUsuarioLogin ] = useState<string | null>(null);
    const [ senha, alterarSenha ] = useState<string | null>(null);
    const [ carregamento, alterarCarregamento ] = useState<boolean>(false);
    const [ erro, alterarErro ] = useState<boolean>(false);

    const { alterarUsuario, alterarLogado } = useContexto();
    const navigate = useNavigate();
    const toast = useToast();
    const theme = useTheme();

    useEffect(() => {
        if(erro){
            setTimeout(()=>{
                alterarErro(false);
            }, 5000);            
        }
    }, [erro])

    // função para logar no sistema
    async function logar() {

        // mostra carregamento
        alterarCarregamento(true);

        try {

            // cria parametros
            let parametros = {
                usuario: usuarioLogin,
                senha: senha
            }

            // envia os dados para o php
            const { data } = await axios.post('/usuarios/verificaLogin.php', { ...parametros });             
            
            // verifica se logou
            if(!data.status){                
                toast({
                    title: data.resposta,
                    description: "",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                alterarErro(true);
            } else {
                toast({
                    title: data.resposta,
                    description: "",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                
                // insere dados de login ao contexto
                alterarUsuario(Object.assign(data.dados, {
                    chaveApiGoogle: 'AIzaSyD10LPSjaY2iwyWqRxoRtF560OWLWLJ7II'
                }))
                alterarLogado(true);
                navigate('/home/veiculos-mapa');
                window.localStorage.setItem('@bem-que-te-vi', JSON.stringify(data.dados));
            }            

        } catch(e) {
            console.log(e);
            alterarErro(true);
        } finally {

            // esconde o carregamento
            alterarCarregamento(false);

        }

    } 
 
    // render
    return(
        
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'space-evenly'}
            bg={theme.cores.fundoGeral}
            flexDirection={'column'}
        >
            <Flex flexDirection={'column'} w={isMobile ? '90%' : '35vw'} align={'center'} justify={'center'}>
                <Flex w={'50%'} flexGrow={1} h={'auto'} align={'center'} justify={'center'}>
                    <Image src={logo} alt='Imagem login' />
                </Flex>
                <Box 
                    margin={'20px 10px'} 
                    color={theme.cores.texto}
                    fontFamily={`${theme.fontes.titulo}, sans-serif`}
                >
                    <Flex align={'center'} justify={'center'}>
                        <Text margin={0} fontSize={'3xl'}>Bem-vindo!</Text>
                    </Flex>
                    <Flex align={'center'} justify={'center'} textAlign={'center'}>
                        <Text margin={0} fontSize={'xl'}>digite seu usuário e senha para entrar</Text>
                    </Flex>
                </Box>
                <Flex justify={'space-between'} align={'center'} flexDirection={'column'} flex={1} w={'100%'}>
                    <div className="label-float">
                        <input 
                            type="text" 
                            placeholder=" " 
                            value={usuarioLogin || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => alterarUsuarioLogin(e.target.value)}
                            required 
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    logar()
                                }
                            }}
                            style={erro ? { borderColor: '#BD0000' } : {}}
                        />
                        <label>Usuário</label>
                    </div>                   
                    <div className="label-float" style={{ marginTop: 10 }}>
                        <input 
                            type="password" 
                            placeholder=" " 
                            required 
                            value={senha || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => alterarSenha(e.target.value)}
                            onKeyPress={(e) => {    
                                if(e.key === 'Enter'){
                                    logar()
                                }
                            }}
                            style={erro ? { borderColor: '#BD0000' } : {}}
                        />
                        <label>Senha</label>
                    </div>
                    <Flex w={'100%'} flex={1}>
                        <Button 
                            onClick={logar}                            
                            flexGrow={1}
                            cursor={'pointer'}
                            fontSize={'20px'}
                            marginTop={'10px'}
                            minW={'180px'}
                            border={'none'}
                            outline={'none'}
                            borderRadius={'5px'}
                            display={'flex'}
                            bg={theme.cores.topoTopBar}
                            color={theme.cores.texto}
                            p={6}
                            alignItems={'center'}
                            justifyContent={'center'}
                            fontFamily={`${theme.fontes.texto }, cursive`}
                            _active={{
                                opacity: 0.5
                            }}
                            _hover={{
                                opacity: 0.9
                            }}
                        >
                            {!carregamento 
                                ? 'Entrar' 
                                : <ReactLoading type={'spinningBubbles'} color={'#284786'} height={30} width={30} />
                            }
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>          
                             
    )

}
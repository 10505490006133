import { Flex, HStack, Text, Checkbox, Button } from "@chakra-ui/react";
import { faFilter, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { isMobile } from "react-device-detect";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import 'moment/locale/pt-br'

import ModalConfirmacao from "../ModalConfirmacao";
import { ModalDataProps } from '../../interfaces';

interface FiltroTopbarListaProps {
    placeholderFiltroData?: string;    
    dataEspecifica?: boolean;    
    filtroDataDatePicker?: Date;
    modalData?: ModalDataProps;
    alterarFiltroDataDatePicker?: (value: Date) => void;
    alterarDataEspecifica?: (value: any | void) => void;
    alterarFiltroData?: (value: any) => void;    
    alterarModalData?: (value: any) => void;    
}

export default function FiltroTopbarLista({
    placeholderFiltroData,
    dataEspecifica,
    filtroDataDatePicker,
    modalData,
    alterarDataEspecifica,
    alterarFiltroData,
    alterarModalData,
    alterarFiltroDataDatePicker
} : FiltroTopbarListaProps) {

    // instancia que a linguagem é brasileira
    registerLocale('ptBR', ptBR);    

    // resgata tema
    const theme = useTheme();

    // faz um input customizado para o date picker
    const InputCustomizado = ({ value, onClick } : any) => {
        return (
            <Button w={'100%'} onClick={onClick} bg={'#FFF'} alignItems={'center'} justifyContent={'center'}>
                {value}
            </Button>
        )
    }

    return(<>
        <HStack             
            align={'center'} 
            justify={'space-between'} 
            overflow={'hidden'}
            flex={1} 
            minW={'80px'}            
            bg={theme.cores.cinzaClaro} 
            borderRadius={5}
            w={['100%', '100%', 'auto']}
            ml={[0, 0, 5]}
            mt={[5, 5, 0]}
            p={[2, 2, 3]} 
        >
            <HStack 
                cursor={'pointer'} 
                overflow={'hidden'}
                direction={'row'} 
                align={'center'} 
                minW={'100px'}
                justify={['space-around', 'space-between']}
                _hover={{
                    opacity: 0.6,
                    cursor: 'pointer'
                }}
                _active={{
                    opacity: 0.3
                }}
                onClick={() => {
                    if(alterarModalData){
                        alterarModalData({...modalData, mostrar: true })
                    }                    
                }}                
            >    
                <FontAwesomeIcon 
                    icon={faFilter}
                    color={'#000'}
                    style={{ width: isMobile ? 15 : 20, height: isMobile ? 15 : 20 }}
                />    
                <Text 
                    isTruncated={!isMobile}                 
                    color={'#000'}
                    fontSize={['sm', 'sm', 'md']}
                >
                    {placeholderFiltroData}
                </Text>
            </HStack>
            <Flex 
                cursor={'pointer'} 
                align={'center'} 
                justify={['center', 'flex-end']} 
                width={25}
                _hover={{
                    opacity: 0.6,
                    cursor: 'pointer'
                }}
                _active={{
                    opacity: 0.3
                }}
                onClick={() => {
                    if(alterarFiltroData){
                        alterarFiltroData('0000-00-00');
                    }                    
                }}
            >
                <FontAwesomeIcon 
                    icon={faTimesCircle}
                    color={'gray'}
                    style={{ width: isMobile ? 20 : 25, height: isMobile ? 20 : 25 }}
                />
            </Flex>            
        </HStack>   
        {modalData &&     
            <ModalConfirmacao 
                titulo={modalData.titulo}
                textoBotaoSalvar={modalData.textoBotaoSalvar}
                mostrar={modalData.mostrar}
                aoFechar={() => {
                    if(alterarModalData && alterarFiltroDataDatePicker){
                        alterarModalData({...modalData, mostrar: false})
                        alterarFiltroDataDatePicker(new Date());
                    }
                }}
                aoSalvar={() => {  
                    if(alterarModalData && alterarFiltroData){              
                        alterarModalData({...modalData, mostrar: false})                               
                        alterarFiltroData(!dataEspecifica ? moment(filtroDataDatePicker).format('YYYY-MM') + '-00' : moment(filtroDataDatePicker).format('YYYY-MM-DD'))                
                    }
                }}
            >
                {alterarFiltroDataDatePicker && alterarDataEspecifica &&
                    <Flex align={'center'} justify={'space-between'} w={'100%'}>                      
                        <DatePicker
                            selected={filtroDataDatePicker}
                            onChange={(date:any) => alterarFiltroDataDatePicker(date)}
                            dateFormatCalendar={"MMMM yyyy"}     
                            dateFormat={dataEspecifica ? 'dd/MM/yyyy'  :'MM/yyyy'}           
                            maxDate={new Date()}                            
                            dropdownMode= "scroll"
                            locale="ptBR"                          
                            customInput={<InputCustomizado />}
                            showMonthDropdown
                            showYearDropdown          
                            scrollableMonthYearDropdown
                            className='date-picker'
                        />
            
                        <Checkbox
                            color={theme.cores.texto}
                            size={'md'}
                            fontSize={14}
                            fontFamily={`${theme.fontes.texto}, sans-serif`}             
                            w={isMobile ? '50%' : '60%'}
                            spacing={isMobile ? '-5px' : 'auto'}
                            ml={5}       
                            isChecked={dataEspecifica}
                            onChange={() => alterarDataEspecifica(!dataEspecifica)}
                        >
                            Escolher uma data específica
                        </Checkbox>

                    </Flex>
                }
            </ModalConfirmacao>
        }
    </>)

}
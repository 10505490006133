import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
    Spinner
  } from '@chakra-ui/react'

interface CarregamentoProps {
    mensagem?: string;
    mostrar: boolean;
}

export default function Carregamento({
    mensagem='Por favor, aguarde',
    mostrar
}: CarregamentoProps) {

    return (
        <Modal 
            isOpen={mostrar} 
            onClose={()=>{}} 
            isCentered            
        >
            <ModalOverlay />
            <ModalContent bgColor={'#333'}>
                <ModalBody bg={'transparent'} textAlign={'center'}>
                    <Spinner size={'xl'} color={'#4A6CAD'} />
                    <Text fontWeight='bold' mt='1rem' fontSize={18} color={'#FFF'}>
                        {mensagem}
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}
import { Container, Text } from "@chakra-ui/react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";

interface PaginacaoProps {
    pagina: number;
    alterarPagina: (valor: number) => void;
}

export default function Paginacao({
    pagina=1,
    alterarPagina
}: PaginacaoProps) {

    const theme = useTheme();

    return(
        <Container
            maxW={'container.sm'}
            centerContent
            flexDirection={'row'}
            maxH={50}
            justifyContent={'space-between'}
            margin={'10px'}
            color={theme.cores.texto}
        >
            <Tooltip label={'Anterior'} aria-label='anterior'>
                <IconButton
                    aria-label='anterior'
                    icon={<FontAwesomeIcon 
                        icon={faChevronLeft}
                        color={theme.cores.texto}
                    />}
                    bg={theme.cores.topoTopBar}
                    onClick={() => alterarPagina(pagina - 1)}
                    disabled={pagina < 2}
                    _hover={{
                        opacity: pagina > 1 ? 0.6 : 0.4,
                        cursor: pagina > 1 ? 'pointer' : 'auto'
                    }}
                    _active={{
                        opacity: 0.3
                    }}
                    _focus={{
                        borderStyle: 'none',
                        textDecoration: 'none'
                    }}
                />
            </Tooltip>
            <Text
                fontFamily={`${theme.fontes.texto}, sans-serif`}
                fontSize={['md', '2xl']}
            >
                {pagina}
            </Text>
            <Tooltip 
                label={'Próximo'} 
                aria-label='proximo'
            >
                <IconButton 
                    aria-label='próximo'
                    icon={<FontAwesomeIcon 
                        icon={faChevronRight}
                        color={theme.cores.texto}
                    />}
                    bg={theme.cores.topoTopBar}
                    onClick={() => alterarPagina(pagina + 1)}
                    _hover={{
                        opacity: 0.6,
                        cursor: 'pointer'
                    }}
                    _active={{
                        opacity: 0.3
                    }}
                    _focus={{
                        borderStyle: 'none',
                        textDecoration: 'none'
                    }}
                />
            </Tooltip>
        </Container>
    )


}
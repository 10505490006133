import React from "react";
import { Flex, HStack } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";

import { ModalDataProps } from '../../interfaces';
import FiltroTopbarLista from "../FiltroTopbarLista";

interface TopbarListaProps {
    children?: React.ReactNode;
    mostrarFiltroData?: boolean;
    placeholderFiltro?: string;
    dataEspecifica?: boolean;
    filtroDataDatePicker?: Date;
    modalData?: ModalDataProps;
    tipoLista?: string;
    alterarFiltroData?: (value: any) => void;
    alterarModalData?: (value: any) => void;
    alterarFiltroDataDatePicker?: (value: any) => void;
    alterarDataEspecifica?: (value: any) => void;
}

export default function TopbarLista({
    children,
    placeholderFiltro,
    dataEspecifica,
    filtroDataDatePicker,
    modalData,
    tipoLista='',
    mostrarFiltroData=true,
    alterarFiltroData,
    alterarModalData,
    alterarFiltroDataDatePicker,
    alterarDataEspecifica
} : TopbarListaProps) {

    return(        
        <Flex   
            flex={1} 
            w={'100%'}
            direction={['column', 'column', 'row']}
            align={'center'} 
            paddingInline={['0px', '10px']}
            justify={'space-between'} 
            mb={3}
        >                                              
            <HStack 
                w={['100%', '100%', (mostrarFiltroData ? '70%' : '100%')]} 
                direction={'row'} 
                align={'center'} 
                justify={isMobile && tipoLista === 'notificacao' ? 'space-between' : (isMobile ? 'center' : 'flex-start')} 
                height={isMobile && tipoLista === 'viagem' ? 55 : 'auto'}
                mt={isMobile ? 0 : [5, 0]}
            >
                {children}                 
            </HStack>
            {mostrarFiltroData &&
                <FiltroTopbarLista 
                    placeholderFiltroData={placeholderFiltro}
                    dataEspecifica={dataEspecifica}
                    filtroDataDatePicker={filtroDataDatePicker}
                    modalData={modalData || {
                        mostrar: false,
                        titulo: '',
                        textoBotaoSalvar: ''
                    }}
                    alterarDataEspecifica={alterarDataEspecifica}
                    alterarFiltroData={alterarFiltroData}
                    alterarModalData={alterarModalData} 
                    alterarFiltroDataDatePicker={alterarFiltroDataDatePicker}                               
                />
            }
        </Flex>                                         
    )

}
import { useTheme } from 'styled-components';
import { Flex, Text, VStack, HStack, Box } from '@chakra-ui/layout';
import { isMobile } from 'react-device-detect';
import InputMask from 'react-input-mask';
import Icon from '@mdi/react'

interface QuadroEstatisticaProps {
    textoTopbar: string;
    icone: string;
    iconeDois?: string;
    texto: string | number;    
    textoDois?: string | number;
    descricao?: string | undefined;
    descricaoDois?: string | undefined;
    primeiro?: boolean;
    mascaraDescricao?: boolean;
    endereco?: string;
}

export default function QuadroEstatistica({
    textoTopbar='',
    icone='',
    iconeDois='',
    texto='',
    textoDois='',
    primeiro=false,
    descricao='',
    descricaoDois='',
    mascaraDescricao=false,
    endereco=''
} : QuadroEstatisticaProps) {

    const theme = useTheme();

    return <Flex 
        h={primeiro ? 180 : 120} 
        borderWidth={1}
        borderColor={theme.cores.topoTopBar}      
        borderRadius={5}                                                                                 
        direction={'column'}                                        
        align={'center'}
        w={'100%'}
    >         
        <Flex
            p={'5px 5px'}
            w={'100%'}
            bg={theme.cores.topoTopBar}
            align={'center'}
            justify={'center'}            
        >
            <Text                 
                h={'100%'}
                isTruncated
                display={'flex'}
                fontFamily={`${theme.fontes.texto}, sans-serif`}
                fontSize={['md', 'lg', 'xl']}                
            >
                {textoTopbar}
            </Text>
        </Flex>        
        <Flex 
            align={'center'}
            justify={'center'}
            direction={'column'}           
            flex={1} 
            w={'100%'}
            bg={theme.cores.fundoGeral}
            borderBottomRadius={5}
        >
            <Flex
                flex={1}
                width={'100%'}
                align={'center'}
                justify={primeiro ? 'space-between' : 'center'}
                p={'10px 10px'}
                pos={'relative'}       
            >
                <HStack
                    w={primeiro ? '50%' : '100%'}
                    flex={1}
                    align={'center'}
                    justify={primeiro ? 'center' : 'flex-start'}
                    gridGap={[0, 2]}                          
                >                
                    <Flex
                        align={'center'}
                        justify={primeiro ? 'center' : 'flex-start'}       
                        w={!primeiro && isMobile ? 'auto': 50}
                        position={primeiro || isMobile ? 'relative' : 'absolute'}     
                    >
                        <Box
                            as={Icon}
                            path={icone}
                            w={[35, 42]}
                            color={theme.cores.texto}                  
                        />
                    </Flex>
                    <VStack
                        align={'center'}
                        justify={'center'}
                        spacing={'-8px'}      
                        flex={primeiro ? 0 : 1}                       
                        marginInlineStart={'0px !important'}                                                                        
                    >
                        <Text                                         
                            isTruncated
                            display={'flex'}
                            justifyContent={'center'}
                            fontFamily={`${theme.fontes.titulo}, cursive`}
                            fontSize={['sm', 'md', 'lg', '2xl']}    
                            w={'100%'}                                      
                        >
                            {texto}
                        </Text>

                        {/* verifica se foi passado descrição */}
                        {(descricao && descricao !== '') && <>

                            {/* verifica se é mascara */}
                            {mascaraDescricao ?                             
                                <InputMask 
                                    value={descricao}
                                    mask={'+99 (99) 99999-9999'}
                                    disabled
                                    style={{
                                        backgroundColor: 'transparent',
                                        margin: 0,
                                        flex: 1,
                                        cursor: 'text',
                                        lineHeight: 'normal',
                                        justifyContent: 'center'
                                    }}                                    
                                />
                            
                                : <Text                                         
                                    isTruncated
                                    display={'flex'}
                                    fontFamily={`${theme.fontes.texto}, sans-serif`}
                                    fontSize={['sm', 'md']}     
                                    justifyContent={'center'}
                                    w={'100%'}       
                                >
                                    {descricao}
                                </Text>
                            }

                        </>}

                    </VStack>                
                </HStack>
                {primeiro && <HStack
                    w={'50%'}
                    flex={1}
                    align={'center'}
                    justify={'center'}   
                    gridGap={2}                                                                            
                >                
                    <Flex
                        align={'center'}
                        justify={'center'}                    
                    >
                        <Box
                            as={Icon}
                            path={iconeDois}
                            w={[35, 42]}
                            color={theme.cores.texto}                  
                        />
                    </Flex>
                    <VStack
                        align={'center'}
                        justify={'center'}
                        spacing={'-10px'} 
                        marginInlineStart={'0px !important'}                          
                    >
                        <Text                                         
                            isTruncated
                            display={'flex'}
                            fontFamily={`${theme.fontes.titulo}, cursive`}
                            fontSize={['sm', 'md', 'lg', '2xl']}                   
                        >
                            {textoDois}
                        </Text>
                        <Text                                         
                            isTruncated
                            display={'flex'}
                            fontFamily={`${theme.fontes.texto}, sans-serif`}
                            fontSize={['xs', 'sm', 'md']}                                            
                        >
                            {descricaoDois}
                        </Text>
                    </VStack>                
                </HStack>}
            </Flex>  
            {(primeiro) && <Flex
                flex={1}
                w={'100%'}
                alignItems={'center'}
                justify={'center'}
                paddingInline={5}
            >           
                <Text
                    isTruncated
                    flex={1}           
                    fontFamily={`${theme.fontes.titulo}, cursive`}
                    fontSize={['lg', 'xl', '2xl']}                                                    
                >
                    {endereco}
                </Text>     
            </Flex>}
        </Flex>                                                                                                                                                                        
    </Flex>                                                                                                                                                                                                                                                                                                                                                                      

}
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { Tooltip, Container, Progress } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { 
    faCar, 
    faMotorcycle, 
    faSignal, 
    faChartLine,
    faAmbulance
} from '@fortawesome/free-solid-svg-icons';
import { 
    Box, 
    Flex, 
    Text, 
    Divider, 
    VStack, 
    Stack, 
    Center
} from '@chakra-ui/layout';
import Icon from '@mdi/react';
import { mdiFireTruck } from '@mdi/js';

import { ListaProps } from '../../interfaces';
import Paginacao from '../Paginacao';
import TopbarLista from '../TopbarLista';

export default function Lista({
    pagina,    
    dados,
    placeholderFiltro,
    childTopbarLista,
    modalData,
    tipoLista,
    icone,
    mostrarFiltroData,
    esconderBlocoCinza,
    estiloContainer,
    estiloContainerLista,
    estiloBlocoCinza,
    estiloBloco,
    nomeIcone,
    componenteCustomizado,
    textoPrincipal,
    textoSecundario,
    labelTooltip,
    alterarFiltroData,
    alterarModalData,
    alterarPagina,
    registroClicado
} : ListaProps) {
    
    const theme = useTheme();         

    const [ filtroDataDatePicker, alterarFiltroDataDatePicker ] = useState<Date>(new Date());
    const [ dataEspecifica, alterarDataEspecifica ] = useState<boolean>(false);     

    // gera cor aleatória
    const randomColor = () => ('#' + ((Math.random() * 0xffffff) << 0).toString(16) + 'fff000').slice(0, 7);

    // define a cor do fundo do veículo de acordo com seu modo
    function defineCorFundoVeiculo(modo: string) {

        // define cor
        let cor = theme.cores.cinza;

        // verifica modo
        switch(modo){

            // se for modo um
            case "1":        
            
                // a cor é vermelha
                cor = theme.cores.vermelho;
                break;

            case "2":
                
                // a cor é verde
                cor = theme.cores.verde;
                break;

            default:

                // a cor é cinza
                cor = theme.cores.cinza;
                break;
        }

        // retorn a cor
        return cor;

    }

    // função para verificar que tipo de marcador é para ser inserido no mapa
    function verificaMarcadorMapa(tipo: string, modeloParametro: string): JSX.Element {

        // verifica modelo
        const modelo = typeof modeloParametro !== 'string' ? '' : modeloParametro.trim();

        // prepara marcador, setando carro como padrão
        let marcador = faCar;        

        // se for ambulancia
        if(modelo === 'Ambulância' && tipo === '1') marcador = faAmbulance;                
        
        // se for caminhão
        if((modelo === 'Caminhão' && tipo === '3') || tipo == '3') return <Icon 
            path={mdiFireTruck}     
            style={{
                width: '70%',
                height: '70%'
            }} 
            color={theme.cores.texto}                                  
        />;

        // verifica o tipo
        switch(tipo) {

            case '1':
                if(modelo !== 'Ambulância') marcador = faCar;      
                break;
            case '2':
                marcador = faMotorcycle;
                break;
                      
        }                                    

        // retorna o ícone com seu path
        return <FontAwesomeIcon 
            icon={marcador}
            color={theme.cores.texto}
            style={{
                width: '70%',
                height:'70%'
            }}
        />
        
    }
    
    return(<>      
        <Center w={'100%'} flexDirection={'row'} flex={1} p={['0px', '0px', '10px']} overflow={'auto'} borderRadius={5}>
            <Container 
                centerContent
                maxW={'container.xl'} 
                align={'center'} 
                justify={'center'} 
                p={'10px'}         
                direction={'column'}                          
                borderRadius={[0, 0, 5]}
                bg={theme.cores.fundoBotaoTransparente}        
                {...estiloContainer}
            >   
                <Box 
                    overflowY={'auto'} 
                    overflowX={'hidden'}
                    w={'100%'} 
                    alignItems={'center'} 
                    justifyContent={'center'} 
                >
                    <TopbarLista
                        mostrarFiltroData={mostrarFiltroData}
                        tipoLista={tipoLista}
                        placeholderFiltro={placeholderFiltro}
                        alterarFiltroData={alterarFiltroData}
                        modalData={modalData}
                        alterarModalData={alterarModalData}
                        filtroDataDatePicker={filtroDataDatePicker}
                        alterarFiltroDataDatePicker={alterarFiltroDataDatePicker}
                        dataEspecifica={dataEspecifica}
                        alterarDataEspecifica={alterarDataEspecifica}                        
                    >
                        {childTopbarLista}
                    </TopbarLista>                                        
                    {tipoLista !== 'viagem' ? <Divider borderBottomWidth={3} mb={5} /> : (!isMobile && <Divider borderBottomWidth={3} mb={5} />)}
                    <Box 
                        align={'center'} 
                        justify={'center'} 
                        direction={'column'} 
                        w={'100%'} 
                        overflow={'auto'}
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: theme.cores.topoTopBar,
                                borderRadius: '10px',
                            }
                        }} 
                        {...estiloContainerLista}                        
                    >
                        {/* Percorre registros */}
                        {dados.map((dado:any, indice:number) => <Tooltip key={indice} label={labelTooltip(dado)}>
                            <Flex align={'center'} justify={'center'} w={'100%'}>                                
                                <Stack direction={'column'} w={'100%'} h={'100%'} padding={['5px', '10px 5px']}>                                                                    
                                    <Flex 
                                        h={100} 
                                        w={'100%'} 
                                        bg={theme.cores.topoTopBar}     
                                        minW={'300px'}                                        
                                        borderRadius={10}                       
                                        borderColor={theme.cores.preto}                      
                                        borderWidth={1}
                                        onClick={() => registroClicado(dado)}
                                        _hover={{
                                            opacity: 0.6,
                                            cursor: 'pointer'
                                        }}
                                        _active={{
                                            opacity: 0.3
                                        }}
                                        {...estiloBloco}
                                    >                                                                            
                                        <Flex 
                                            direction={'row'} 
                                            h={'100%'} 
                                            w={'100%'} 
                                            align={'center'} 
                                            pos={'relative'}
                                            justify={esconderBlocoCinza ? 'center' :'space-between'}
                                        >                                                                                        
                                            {esconderBlocoCinza ? <></> :
                                                tipoLista === 'veiculos' && dado.tipo === '' ? <></> :
                                                    <Flex 
                                                        w={[100, 150]} 
                                                        minW={'50px'}
                                                        height={'100%'} 
                                                        borderStartRadius={10} 
                                                        bg={tipoLista === 'veiculos' ? (Number(dado.time) > 0 ? theme.cores.cinza : defineCorFundoVeiculo(String(dado.modo))) : theme.cores.cinza}                                                     
                                                        justify={'center'}                                                     
                                                        align={'center'}
                                                        color={theme.cores.preto}
                                                        direction={'column'}
                                                        {...estiloBlocoCinza}
                                                    >
                                                        {!icone ? <>
                                                            <Text 
                                                                align={'left'} 
                                                                fontFamily={`${theme.fontes.titulo}, sans-serif`}
                                                                mb={-1}
                                                                fontSize={['2xl', '4xl']}
                                                            >
                                                                {moment(dado.dt).format('DD')}
                                                            </Text>
                                                            <Text 
                                                                align={'left'} 
                                                                fontFamily={`${theme.fontes.texto}, sans-serif`}
                                                                fontSize={['lg','xl']}
                                                                mb={-1}
                                                            >
                                                                {moment(dado.dt).format('MMM')}
                                                            </Text>                                                    
                                                        </> : (nomeIcone ? 
                                                                <FontAwesomeIcon 
                                                                    icon={nomeIcone}
                                                                    color={theme.cores.texto}
                                                                    style={{ width: 30, height: 30 }}
                                                                />
                                                                : verificaMarcadorMapa(dado.tipo, dado.modelo)
                                                            )
                                                        }
                                                    </Flex> 
                                            }                                        
                                            <Box 
                                                padding={['2px', '15px 15px']} 
                                                marginInline={[1, 5]} 
                                                flex={1} 
                                                align={'center'} 
                                                justify={'center'} 
                                                color={theme.cores.texto}
                                            >
                                                <VStack spacing={'-1px'} flex={1} align={'flex-start'} justify={'center'}>
                                                    <Text 
                                                        align={'left'} 
                                                        color={theme.cores.texto} 
                                                        fontFamily={`${theme.fontes.titulo}, sans-serif`}
                                                        fontSize={['md', 'lg']}
                                                        mb={-1}
                                                        isTruncated
                                                    >      
                                                        {textoPrincipal(dado)}      
                                                    </Text>                                                    
                                                    <Text fontSize={['sm', 'md']} align={'left'} color={'gray'} isTruncated>
                                                        {textoSecundario(dado)}
                                                    </Text>                                                
                                                    {tipoLista === 'notificacao' && 
                                                        <Text align={'left'} color={'gray'} fontSize={['sm', 'md']} isTruncated>
                                                            Emergências: {dado.emg}
                                                        </Text>
                                                    }
                                                    {tipoLista === 'quilometragem' &&                                                    
                                                        <Flex w={'100%'} alignItems={'center'} justifyContent={'flex-start'}>
                                                            <Progress
                                                                width={`${Math.floor((100 * dado.distancia)/dado.distanciaTotal)}%`}
                                                                isAnimated
                                                                height={['10px', '15px']}
                                                                borderRadius={5}                                                     
                                                                bg={randomColor()}
                                                                mt={[1, 0]}
                                                            />                                                                                                                                                                                       
                                                            <Text                                                         
                                                                ml={2}
                                                                fontSize={['sm', 'md']}
                                                                noOfLines={2}
                                                                isTruncated
                                                            >
                                                                {dado.distancia} km
                                                            </Text>    
                                                        </Flex>
                                                    }         
                                                    {componenteCustomizado && componenteCustomizado}                                           
                                                </VStack>
                                            </Box>
                                            {(dado.idRastreador > 0 || tipoLista === 'quilometragem' ) && <Box w={tipoLista === 'quilometragem' ? '50px' : 'auto'} paddingTop={'10px'} alignItems={'flex-end'} justifyContent={'flex-end'} height={'100%'}>
                                                <Flex w={25} h={25} bg={tipoLista === 'quilometragem' ? 'none' : '#FFF'} align={'center'} justify={'center'}>
                                                    <FontAwesomeIcon
                                                        icon={tipoLista === 'quilometragem' ? faChartLine : faSignal}
                                                        color={tipoLista === 'quilometragem' ? theme.cores.texto : ((Number(dado.time) > 0) ? theme.cores.vermelho : theme.cores.verde)}
                                                        style={tipoLista === 'quilometragem' 
                                                            ? { 
                                                                fontSize: isMobile ? 20 : 30 
                                                            } 
                                                            : { 
                                                                width: 15, 
                                                                height: 15 
                                                            }
                                                        }
                                                    />
                                                </Flex>
                                            </Box>}
                                        </Flex>                                                                               
                                    </Flex>                                        
                                </Stack>
                            </Flex>
                        </Tooltip>)}                            
                    </Box>                                        
                </Box>    
                <Divider borderBottomWidth={3} mt={5} /> 
                <Paginacao 
                    pagina={pagina}
                    alterarPagina={alterarPagina}
                />            
            </Container>
        </Center>           

    </>)

}
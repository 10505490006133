import axios from 'axios';
import { useState, useEffect, Fragment } from 'react';
import { useTheme } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { 
    Box, 
    Flex, 
    Text, 
    Divider, 
    Stack, 
    Grid, 
    Container 
} from '@chakra-ui/layout';
import { 
    mdiLatitude, 
    mdiLongitude, 
    mdiBoxShadow, 
    mdiSim, 
    mdiSpeedometer, 
    mdiMapMarkerDistance, 
    mdiWalletTravel, 
    mdiRoadVariant, 
    mdiSatelliteVariant, 
    mdiBatteryChargingMedium 
} from '@mdi/js'
import moment from 'moment';

import Topbar from '../../componentes/Topbar';
import { EstatisticaVeiculoProps } from '../../interfaces';
import Grafico from '../../componentes/Grafico';
import QuadroEstatistica from '../../componentes/QuadroEstatistica';
import { useContexto } from '../../Contextos/Contexto';
import buscaEndereco from '../../funcoes/buscaEndereco';

export default function EstatisticaVeiculo() {

    const { pathname } = useLocation();    
    const { id } = useParams();
    const { usuario, carregamento, alterarCarregamento } = useContexto();
    const theme = useTheme();       
    const navigate = useNavigate();

    const [ endereco, alterarEndereco ] = useState<string>('não foi possível carregar o endereço');
    const [ estatisticasVeiculo, alterarEstatisticasVeiculo ] = useState({} as EstatisticaVeiculoProps); 
    const [ dadosGrafico, alterarDadosGrafico ] = useState([]);
    const [ labelsGrafico, alterarLabelsGrafico ] = useState([]);        
    
    useEffect(() => {        
        // carrega função
        (async () => await carregaQuilometragem())();     
    }, [])

    // função que carrega os veículos no mapa
    async function carregaQuilometragem() { 

        // mostra carregamento
        alterarCarregamento({ mostrar: true });
        
        try {
            
            // faz a requisição
            const { data } = await axios({
                url: '/veiculos/carregaEstatisticas.php',
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data:{
                    id
                }
            })                         
            
            // altera estados
            alterarEstatisticasVeiculo(data);            
            alterarDadosGrafico(data.graficos.dados.reverse());
            alterarLabelsGrafico(data.graficos.labels.reverse());                        
            
            // resgata a resposta do endereço
            const enderecoPesquisado = await buscaEndereco({
                usuario, 
                lat: data.lat, 
                lng: data.lng, 
                alterarCarregamento
            });           

            // altera o endereço
            alterarEndereco(enderecoPesquisado);

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });
        
        }

    }       

    // cria componente Texto top bar gráfico
    const ComponenteTextoTopbar = () => {

        // cria array para percorrer no componente
        const estatisticasTopbar = [
            { 
                label: 'Placa:', 
                valor: estatisticasVeiculo.placa 
            },
            { 
                label: 'Cor:',  
                valor: estatisticasVeiculo.cor 
            },
            { 
                label: 'Ano:', 
                valor: estatisticasVeiculo.ano 
            },
            { 
                label: 'Média Mensal:', 
                valor: estatisticasVeiculo.media_km_mensal 
            }
        ]
        
        // verifica se não está carregando e se possui dados
        return(carregamento.mostrar || !estatisticasVeiculo.placa ? <></> : <Flex 
                fontFamily={`${theme.fontes.texto}, sans-serif`} 
                align={'center'} 
                direction={'row'} 
                w={'100%'} 
                h={'80%'}
                flex={1} 
                justify={'space-evenly'}
            >    

                {/* percorre para inserir dados do topbar do gráfico */}
                {estatisticasTopbar.map((estatistica, indice) => {

                    // retorna
                    return(<Fragment key={indice}>
                        <Text                         
                            fontSize={['xs', 'sm', 'md']}
                            align={'center'} 
                        >
                            {/* retorna valores */}
                            {estatistica.label} {estatistica.valor}
                        </Text>

                        {/* se for o último item não insere divisor */}
                        {indice !== (estatisticasTopbar.length - 1) &&
                            <Divider orientation='vertical' marginInline={5} />                                          
                        }
                    </Fragment>)

                })}     

            </Flex>
        )

    }    

    // se estiver carregando não mostra o componente
    return(<>        

        <Box direction={'column'} overflow={'auto'} bg={'transparent'}>
            <Flex 
                direction={'column'}                 
                h={'100vh'} 
            >

                <Topbar 
                    rota={pathname} 
                    titulo={`${estatisticasVeiculo.marca || ''} ${estatisticasVeiculo.modelo || ''}`} 
                    navigate={navigate} 
                />                        

                {/* verifica se está carregando */}
                {!carregamento.mostrar && <Container gridGap={['2em', '2em', '4em']} h={'100%'} m={'auto'} maxW={'100%'} centerContent flexDirection={['column', 'column', 'row']} p={'3em'}>
                    <Flex minW={'300px'} w={'100%'} align={'center'} justify={'center'}>
                        <Grafico                        
                            topbarGrafico={true}
                            dadosGrafico={dadosGrafico}
                            labelsGrafico={labelsGrafico}
                            childrenTopbar={<ComponenteTextoTopbar />}
                            cliqueGrafico={false}
                        />
                    </Flex>
                    <Flex minW={'350px'} padding={'10px 0px'} w={'100%'} align={'center'} justify={'center'}>
                        <Box 
                            w={['100%', '100%', '85%']} 
                            h={['auto', 550]} 
                            align={'center'} 
                            justify={'center'} 
                            direction={'column'}                            
                        >                                                
                            <Box 
                                align={'center'} 
                                justify={'center'} 
                                direction={'column'} 
                                w={'100%'} 
                                maxH={'99%'} 
                                display={'block'}      
                                overflow={'auto'}                           
                                css={{
                                    '&::-webkit-scrollbar': {
                                        width: '8px'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: theme.cores.topoTopBar,
                                        borderRadius: '10px',
                                    }
                                }}                          
                            >
                                <Flex align={'center'} justify={'center'} w={'100%'}>
                                    <Stack 
                                        direction={'column'} 
                                        w={'100%'} 
                                        h={'100%'}                                    
                                        color={theme.cores.texto}                                    
                                    >                           
                                        <QuadroEstatistica 
                                            textoTopbar={moment(estatisticasVeiculo.data + ' ' + estatisticasVeiculo.hora).format('LLL')}
                                            texto={estatisticasVeiculo.lat}
                                            textoDois={estatisticasVeiculo.lng}
                                            icone={mdiLatitude}
                                            iconeDois={mdiLongitude}
                                            primeiro={true}
                                            endereco={endereco}
                                            descricao={'latitude'}
                                            descricaoDois={'longitude'}                                            
                                        />
                                        {/* verifica se possui dados */}
                                        {estatisticasVeiculo && Object.keys(estatisticasVeiculo).length > 0 && <Box>
                                            <Grid
                                                templateColumns={'1fr 1fr'}
                                                gridGap={3}
                                                p={'2px'}
                                            >
                                                {
                                                    [
                                                        {
                                                            textoTopbar: 'rastreador',
                                                            texto: estatisticasVeiculo.rastreador,
                                                            descricao: estatisticasVeiculo.id_rastreador,
                                                            icone: mdiBoxShadow,             
                                                        },
                                                        {
                                                            textoTopbar: 'chip de dados',
                                                            texto: estatisticasVeiculo.operadora,
                                                            descricao: estatisticasVeiculo.numero_chip,
                                                            mascaraDescricao: true,
                                                            icone: mdiSim,            
                                                        },
                                                        {
                                                            textoTopbar: 'velocidade máxima',
                                                            texto: `${estatisticasVeiculo.velocidade_maxima} km/h`,
                                                            icone: mdiSpeedometer,            
                                                        },
                                                        {
                                                            textoTopbar: 'total de km',
                                                            texto: `${estatisticasVeiculo.km_total} km`,
                                                            icone: mdiMapMarkerDistance,            
                                                        },
                                                        {
                                                            textoTopbar: 'total de viagens',
                                                            texto: estatisticasVeiculo.total_viagens,
                                                            icone: mdiWalletTravel,            
                                                        },
                                                        {            
                                                            textoTopbar: 'maior viagem',
                                                            texto: `${estatisticasVeiculo.maior_distancia} km`,
                                                            icone: mdiRoadVariant,            
                                                        },
                                                        {
                                                            textoTopbar: 'satélites',
                                                            texto: estatisticasVeiculo.satelites,
                                                            icone: mdiSatelliteVariant,            
                                                        },
                                                        {
                                                            textoTopbar: 'voltagem da bateria',
                                                            texto: `${estatisticasVeiculo.voltagem} V`,
                                                            icone: mdiBatteryChargingMedium,            
                                                        }
                                                    ].map((fe, i) => {
                                                        return <QuadroEstatistica
                                                            key={i} 
                                                            textoTopbar={fe.textoTopbar}
                                                            texto={fe.texto}
                                                            icone={fe.icone}
                                                            descricao={fe.descricao}    
                                                        />
                                                    })
                                                }
                                            </Grid>
                                        </Box>}
                                    </Stack>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>   

                </Container>}    

            </Flex>
        </Box>               

    </>)

}
import { 
    createContext, 
    useState, 
    ReactNode, 
    useContext,
    useEffect 
} from "react";
import { LoginProps } from '../interfaces';

interface ContextoProviderProps {
    children: ReactNode
}

interface CarregamentoProps {
    mostrar: boolean;
    mensagem?: string;
}

interface ContextoProps {
    logado: boolean;
    usuario: LoginProps;
    carregamento: CarregamentoProps;
    alterarCarregamento(carregamento: CarregamentoProps): void;
    alterarUsuario(usuario: LoginProps): void;  
    alterarLogado(logado: boolean): void;
}


// cria contexto login
const Contexto = createContext({} as ContextoProps);

// cria contexto provider
function ContextoLoginProvider({ children }: ContextoProviderProps){

    // instancia estados
    const [ usuario, alterarUsuario ] = useState({} as LoginProps);
    const [ logado, alterarLogado ] = useState(false);
    const [ analisado, alterarAnalisado ] = useState(false);
    const [ carregamento, alterarCarregamento ] = useState({} as CarregamentoProps);

    useEffect(() => {

        // resgata do armazenamento local
        let login = window.localStorage.getItem('@bem-que-te-vi');             

        // verifica se possui dados locais
        if(login){              

            // altera contexto
            alterarUsuario(Object.assign(JSON.parse(login), {
                chaveApiGoogle: 'AIzaSyD10LPSjaY2iwyWqRxoRtF560OWLWLJ7II'
            }));
            alterarLogado(true);
            
        }

        // analisou
        alterarAnalisado(true);

    }, []);

    // se não tiver analisado, não continua
    if(!analisado) return <></>

    // retorna
    return(
        <Contexto.Provider value={{
            usuario, alterarUsuario,
            logado, alterarLogado,
            carregamento, alterarCarregamento,            
        }}>
            {children}
        </Contexto.Provider>
    );

}

// resgata contexto
function useContexto(){
    const context = useContext(Contexto);
    return context;
}

export { ContextoLoginProvider, useContexto }
export function funcaoRetornaErroAlerta(comando:string, idAlerta:string, outros?:string){

    // variaveis
    var mensagem = "erro não encontrado";

    // verifica se é alerta ou emergencia
    if(comando === "ST300ALT"){

         // verifica o codigo de erro
        switch(parseInt(idAlerta)){

            case 1:
                mensagem = `Excedeu a velocidade limite ${outros} km/h`;
                break;
            case 2:
                mensagem = "Excesso de velocidade finalizada";
                break;
            case 5:
                mensagem = "Veículo fora da área de cerca";
                break;
            case 6:
                mensagem = "Veículo entrou na área de cerca";
                break;
            case 9:
                mensagem = "Rastreador em hibernação";
                break;
            case 10:
                mensagem = "Saiu do modo hibernação";
                break;
            case 13:
                mensagem = "Erro na bateria de backup";
                break;
            case 14:
                mensagem = "Bateria com nível muito baixo";
                break;
            case 15:
                mensagem = "Chocado";
                break;
            case 16:
                mensagem = "Ocorreu uma possísvel colisão";
                break;
            case 40:
                mensagem = "Conectado a bateria principal";
                break;
            case 41:
                mensagem = "Desconectado da bateria principal";
                break;
            case 44:
                mensagem = "Conectado a bateria de backup";
                break;
            case 45:
                mensagem = "Desconectado da bateria de backup";
                break;
            case 50:
                mensagem = "Jamming detectado";
                break;
        }

    }else if(comando === "ST300EMG"){

        // verifica o codigo de erro
         switch(parseInt(idAlerta)){

            case 1:
                mensagem = "Botão de pânico";
                break;
            case 2:
                mensagem = "Bloqueio de estacionamento";
                break;
            case 3:
                mensagem = "Desconectado da bateria principal";
                break;
            case 5:
                mensagem = "Emergência por anti-roubo";
                break;
            case 7:
                mensagem = "Emergência de movimento";
                break;
            case 8:
                mensagem = "Emergência por choque anti-roubo";
                break;
         }

    }else{

        mensagem = "erro no buscar o erro";

    }

    // retorno da funcao
    return mensagem;
}

import Chart from "react-apexcharts";
import { useTheme } from 'styled-components';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { Box, Flex, Text, FlexProps, TextProps } from '@chakra-ui/layout';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { isMobile } from "react-device-detect";

interface GraficoProps {
    dadosGrafico: number[];
    labelsGrafico: number[];
    estiloGrafico?: object;        
    estiloContainerTopbar?: FlexProps;
    estiloTextoTopbar?: TextProps;    
    estiloContainerGrafico?: FlexProps;
    estiloPaiGrafico?: FlexProps;
    topbarGrafico?: boolean;    
    cliqueGrafico?: boolean;
    tituloTopbarGrafico?: string;
    iconeTopbar?: IconDefinition;
    childrenTopbar?: JSX.Element;
}

export default function Grafico({
    dadosGrafico=[],
    labelsGrafico=[],
    estiloGrafico={},
    estiloContainerGrafico={},    
    tituloTopbarGrafico='',
    estiloContainerTopbar={},
    estiloTextoTopbar={},
    estiloPaiGrafico={},
    topbarGrafico=false,
    cliqueGrafico=true,
    iconeTopbar,
    childrenTopbar
} : GraficoProps) {

    const theme = useTheme();      
    const navigate = useNavigate();  
    const randomColor = () => ('#' + ((Math.random() * 0xffffff) << 0).toString(16) + 'fff000').slice(0, 7);
    const meses = [
        'Janeiro', 
        'Fevereiro', 
        'Março', 
        'Abril', 
        'Maio', 
        'Junho', 
        'Julho', 
        'Agosto', 
        'Setembro', 
        'Outubro', 
        'Novembro', 
        'Dezembro'
    ];   
    
    // cria opções para o gráfico
    const grafico = {        
        options: {                 
            yaxis: {
                show: false,
                showAlways: false,
            },
            subtitle: {
                text: undefined,
            },
            legend: {
                show: false
            },
            grid: {
                show: false,
            },
            plotOptions: {
                bar: {             
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: '#000',
                    borderStyle: 'solid',
                    columnWidth: '90%',
                    barHeight: '70%',
                    distributed: true,
                    dataLabels: {
                        position: 'center',
                        maxItems: labelsGrafico.length,
                    },                    
                },
            } ,                  
            chart: {
                id: "basic-bar",
                background: theme.cores.fundoGeralEscuro,
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      selection: false,
                    },
                },
                sparkline: {
                    enabled: false,
                },
                stacked: false,
                fontFamily: `${theme.fontes.texto}, sans-serif`,
            },
            colors: labelsGrafico.map(m => randomColor()),
            xaxis: {
                categories: labelsGrafico.map(m => meses[Number(m) -1]) ,
                position: 'bottom',
                labels: {
                    style: {
                        colors: labelsGrafico.map(m => theme.cores.texto),
                        fontSize: isMobile ? '12px' :'16px',
                        fontFamily: `${theme.fontes.texto}, sans-serif`, 
                        fontWeight: 700,
                    }
                },
                crosshairs: {
                    show: true,
                    position: 'back',
                    opacity: 0.9,        
                    stroke: {
                        color: 'rgba(0, 0, 0, 0.5)',
                        width: 0,
                        dashArray: 0,
                    },
                    fill: {
                        type: 'gradient',
                        color: 'rgba(0, 0, 0, 0.5)',
                        gradient: {
                            colorFrom: 'rgba(72, 61, 139, 0.2)',
                            colorTo: '#585883',
                            stops: [0, 100],
                            opacityFrom: 0.2,
                            opacityTo: 1,
                        },
                    },
                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 1,
                        opacity: 0.4,
                    },
                },
            },
            states: {
                active: {
                  filter: {
                    type: 'none' 
                  }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val: any) => {
                    return parseFloat(val).toFixed(2) + 'KM';
                },
                style: {
                    fontSize: isMobile ? '12px' : '16px'
                }
            }
        },
        series: [
            {
                name: "KM",
                data: dadosGrafico
            }
        ]
    };

    return(<>

        <Flex 
            h={550} 
            w={'100%'}
            direction={'column'}
            _hover={cliqueGrafico ? {
                opacity: 0.8,
                cursor: 'pointer'
            } : {}}
            _active={cliqueGrafico ? {
                opacity: 0.5
            } : {}}
            onClick={() => {
                if(cliqueGrafico) navigate('/home/veiculo-quilometragem');
            }}                    
            {...estiloContainerGrafico}
        >   
            {topbarGrafico &&
                <Flex 
                    bg={'rgba(51,51,51,0.8)'}
                    color={theme.cores.texto}
                    direction={'row'}
                    flex={1}
                    padding={'5px 20px'}
                    align={'center'}
                    justify={"flex-start"}
                    borderTopRightRadius={5}
                    borderTopLeftRadius={5}
                    {...estiloContainerTopbar}
                >            
                    {iconeTopbar &&                
                        <FontAwesomeIcon 
                            icon={faChartLine} 
                            color={theme.cores.texto}
                            style={{ width: 30, height: 30 }}                
                        />
                    }
                    {(tituloTopbarGrafico && tituloTopbarGrafico !== '') && 
                        <Text 
                            fontWeight={'bold'} 
                            fontSize={['sm','md']}
                            align={'center'} 
                            fontFamily={`${theme.fontes.titulo}, cursive`} 
                            color={theme.cores.texto} 
                            marginLeft={5}
                            {...estiloTextoTopbar}
                        >
                            {tituloTopbarGrafico}
                        </Text>              
                    }  
                    {childrenTopbar}            
                </Flex>
            }
            <Box 
                w={'100%'} 
                h={500}
                bg={theme.cores.fundoGeralEscuro} 
                flexDirection={'row'} 
                borderBottomLeftRadius={5}
                borderBottomRightRadius={5}
                {...estiloPaiGrafico}
            >
                <Chart 
                    options={grafico.options}
                    series={grafico.series}
                    type={'bar'}
                    height={'98%'}
                    width={'98%'}
                    {...estiloGrafico}
                />
            </Box>
        </Flex>
    
    </>)

}
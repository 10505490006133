import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faChartLine, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Flex, Text, Container } from '@chakra-ui/layout';
import axios from 'axios';

import { useContexto } from '../../Contextos/Contexto';
import '../Login/loginEstilo.scss';
import Grafico from '../../componentes/Grafico/index';
import Lista from '../../componentes/Lista';
import Topbar from '../../componentes/Topbar';
import { isMobile } from 'react-device-detect';

interface ParametrosProps {
    id: number | string;
    id_principal: number | string;
    paginaAtual: number;
    quantidade: number;
    busca: string;
    filtroData: string;
}

export default function Home() {

    // hooks
    const { usuario, carregamento, alterarCarregamento } = useContexto();    
    const { pathname } = useLocation();    
    const theme = useTheme();        
    const navigate = useNavigate();   
    
    // estados
    const [ pagina, alterarPagina ] = useState<number>(1);
    const [ carregouDados, alterarCarregouDados ] = useState<boolean>(false);
    const [ mostrarModal, alterarMostrarModal ] = useState<boolean>(false);
    const [ placa, alterarPlaca ] = useState<string>('');
    const [ veiculosMapa, alterarVeiculosMapa ] = useState([]); 
    const [ dadosGraficos, alterarDadosGraficos ] = useState([]);
    const [ labelsGraficos, alterarLabelsGraficos ] = useState([]); 

    useEffect(() => {
        // carrega função novamente
        carregaVeiculosMapa();
    }, [pagina])

    // função que carrega os veículos no mapa
    async function carregaVeiculosMapa() {
        
        // mostra carregamento
        alterarCarregamento({ mostrar: true });

        // instancia parâmetros
        let parametros : ParametrosProps = {
            id: usuario.id,
            id_principal: usuario.id_principal,
            paginaAtual: pagina,
            quantidade: 30,
            busca: '',
            filtroData: '0000-00-00'
        }
        
        try {

            // faz a requisição
            const retorno = await axios({
                url: '/veiculos/buscaVeiculos.php',
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data:parametros
            })
            
            // altera array
            alterarVeiculosMapa(retorno.data.dados);

            // alterar pra ver se ja carregou todos
            alterarCarregouDados(retorno.data.fim);

            // faz a requisição
            const retornoKmMes = await axios({
                url: `/viagens/buscaKmMes.php`,
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                    id_principal: usuario.id_principal
                }
            })  

            // altera dados dos gráficos
            alterarDadosGraficos(retornoKmMes.data.dados.reverse());
            alterarLabelsGraficos(retornoKmMes.data.labels.reverse());

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });
        
        }

    }

    // faz o cadastro da placa do veículo por api
    async function cadastrarPlaca() {
        
        // mostrar carregamento
        alterarCarregamento({ mostrar: true, mensagem: 'Verificando placa do veículo' });

        try {

            // faz a requisição
            const retornoCadastroVeiculo = await axios({
                url: '/apiPlacas/buscaPlaca.php',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                    placa
                }
            })            

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });

        }

    }

    // componente para o topo do componente lista
    const ComponenteTopbarLista = () => {

        // retorna
        return(
            <Flex   
                flex={1} 
                align={'center'} 
                justify={'center'} 
                pos={'relative'}
                h={isMobile ? 'auto' : '30px'}   
            >
                <Flex
                    onClick={() => {
                        navigate('veiculos-mapa');
                    }}
                    align={'center'}
                    justify={'center'}
                    _hover={{
                        opacity: 0.6,
                        cursor: 'pointer'
                    }}
                    _active={{
                        opacity: 0.3
                    }}
                >
                    <FontAwesomeIcon 
                        icon={faMapMarkedAlt} 
                        color={theme.cores.texto}
                        style={{ width: 30, height: 30 }}                
                    />            
                    <Text 
                        marginBottom={-1} 
                        marginLeft={3} 
                        fontFamily={`${theme.fontes.titulo}, sans-serif`} 
                        color={theme.cores.texto}
                    >
                        Meus Veículos no mapa
                    </Text> 
                </Flex>        
                {/* <Flex 
                    pos={'absolute'} 
                    cursor={'pointer'}
                    right={0} 
                    _hover={{
                        opacity: 0.6,
                        cursor: 'pointer'
                    }}
                    _active={{
                        opacity: 0.3
                    }}
                >
                    <FontAwesomeIcon 
                        icon={faPlusCircle}
                        color={theme.cores.texto}
                        style={{ width: 30, height: 30 }}
                    />    
                </Flex>                */}
            </Flex>
        )

    }
    
    return(<>               
        <Box direction={'column'} overflow={'auto'} h={'100%'} minH={'100vh'}>
            <Flex 
                direction={'column'}                 
                h={'100%'}        
                flex={1}
                m={'auto'}        
            >

                <Topbar 
                    rota={pathname} 
                    navigate={navigate} 
                    veiculoClicado={() => {
                        alterarMostrarModal(true)
                    }}
                />

                {/* verifica se está carregando */}
                {!carregamento.mostrar && <Container 
                    gridGap={['2em', '2em', '4em']} 
                    h={'100%'} 
                    m={'auto'} 
                    maxW={'100%'} 
                    centerContent 
                    flexDirection={['column', 'column', 'row']} 
                    p={isMobile ? '1em' : '3em'}
                >
                    {/* <Flex minW={'300px'} w={'100%'} align={'center'} justify={'center'}>
                        <Grafico
                            tituloTopbarGrafico="km mensal"
                            topbarGrafico={true}
                            dadosGrafico={dadosGraficos}
                            labelsGrafico={labelsGraficos}
                            iconeTopbar={faChartLine}
                        />
                    </Flex> */}
                    <Flex 
                        w={'100%'} 
                        h={'100%'}
                        align={'center'} 
                        justify={'center'}
                        minW={'300px'}
                    >
                        <Lista 
                            pagina={pagina}
                            alterarPagina={alterarPagina}
                            mostrarFiltroData={false}
                            dados={veiculosMapa}
                            icone={true}                                        
                            registroClicado={(veiculo) => {
                                navigate(`viagens/${veiculo.idRastreador}`, {
                                    state: veiculo
                                });
                            }}
                            tipoLista={'veiculos'} 
                            childTopbarLista={<ComponenteTopbarLista />}
                            estiloContainer={{
                                // maxH: 550,
                                width:  isMobile ? '100%' :'80%',
                                p: '10px',
                                paddingBottom: '0px'
                            }}
                            estiloContainerLista={{
                                // maxHeight: 550
                                maxHeight: '50vh'
                            }}
                            estiloBloco={{
                                p: '0px 15px'
                            }}
                            estiloBlocoCinza={{
                                w: 50, 
                                height: 50,
                                borderRadius: 50,
                                justify:'center',
                                align:'center'
                            }}            
                            textoPrincipal={(dado) => `${dado.marca || ''} ${dado.modelo || ''}`}
                            textoSecundario={(dado) => `Placa: ${dado.placa || ''}`}
                            labelTooltip={(dado) => `Velocidade Máxima: ${dado.vmax}km`}                                                        
                        />                                
                    </Flex>
                </Container>}

            </Flex>                                     
        </Box>         

        {/* <ModalConfirmacao 
            mostrar={mostrarModal}
            titulo={'Cadastrar Veículo'}            
            textoBotaoSalvar='Cadastrar'
            textoBotaoFechar='Cancelar'
            aoSalvar={() => cadastrarPlaca()}                                    
            aoFechar={() => alterarMostrarModal(false)}
        >
            <Input 
                value={placa}
                onChange={(e) => alterarPlaca(e.target.value.toUpperCase())}
                placeholder='Placa'
                textTransform={'uppercase'}
                isRequired
                isFullWidth                
                type={'text'}                
                color={theme.cores.texto}
                fontFamily={`${theme.fontes.texto}, sans-serif`}
                focusBorderColor={placa.length > 0 ? theme.cores.texto : theme.cores.cinzaClaro}
            />
        </ModalConfirmacao> */}

    </>)

}
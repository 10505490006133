import Geocode from "react-geocode";
import { LoginProps } from "../interfaces";
import { trataEndereco } from "./tratarEndereco";

interface BuscaEnderecoProps {
    usuario: LoginProps; 
    lat: string; 
    lng: string; 
    alterarCarregamento: (value: any) => void;
}

export default async function buscaEndereco({
    usuario,
    lat,
    lng,
    alterarCarregamento
}: BuscaEnderecoProps) {
        
    Geocode.setApiKey(usuario.chaveApiGoogle);
    Geocode.setLanguage('pt-br');     
    
    // cria texto de endereço
    let textoEndereco = '';

    try {

        // consulta latitude e longitue e retorna resultados
        const { results } = await Geocode.fromLatLng(lat, lng);

        // chama função para resgatar o endereço encotrado
        const { rua, numero, bairro, cidade, uf } = trataEndereco(results[0].address_components);                       

        // verifica se possui rua
        if(rua != null) {
            
            // adiciona a variável
            textoEndereco += `${rua}, ${numero}${'\n'}`;
            
        }

        // verifica se possui bairro
        if(bairro != null) {

            // adiciona a variável
            textoEndereco += `${bairro} - ${cidade}/${uf}`;

        } else {

            // instancia com cidade e estado
            textoEndereco += `${cidade}/${uf}`;

        }           
        
    } catch(e) {
        console.log(e);

        // altera endereço
        textoEndereco = 'não foi possível carregar o endereço';

    }
    
    // retorna texto do endereço
    return textoEndereco;

}
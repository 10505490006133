import { ReactChild, useRef } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
  } from '@chakra-ui/react'
import { useTheme } from 'styled-components';

interface ModalConfirmacaoProps {
    mostrar: boolean;    
    estiloCorpoModal?: object;    
    texto?: string;
    titulo: string;
    textoBotaoFechar?: string;
    textoBotaoSalvar?: string;
    children?: ReactChild | Element[] | JSX.Element[];
    aoRolar?: (valor: any) => void;
    aoFechar: () => void;
    aoSalvar: (retorno?: any) => void;    
}

export default function ModalConfirmacao({
    mostrar,    
    texto,
    titulo,
    textoBotaoFechar,
    textoBotaoSalvar,
    estiloCorpoModal,
    children,
    aoSalvar,
    aoFechar,
    aoRolar    
} : ModalConfirmacaoProps){

    const botaoRef = useRef(null);
    const theme = useTheme();

    return (        
        <Modal 
            isOpen={mostrar} 
            onClose={aoFechar} 
            isCentered 
            initialFocusRef={botaoRef}                  
        >
            <ModalOverlay />
            <ModalContent bgColor={'#4A6CAD'} borderRadius={5} pb={'10px'}>
                <ModalHeader 
                    fontFamily={`${theme.fontes.titulo}, cursive`} 
                    fontSize={['sm', 'md']}
                    borderTopLeftRadius={5} 
                    borderTopRightRadius={5}
                    justifyContent={'space-between'}
                    bg={'#333'} 
                    pos={'relative'}
                    color={'#FFF'}
                >
                    {titulo}
                    <ModalCloseButton 
                        color={'#FFF'} 
                        borderStyle={'none'}
                        h={'20px'}
                        _hover={{
                            opacity: 0.6,
                            cursor: 'pointer'
                        }}
                        _active={{
                            opacity: 0.3
                        }}
                        _focus={{
                            borderStyle: 'none',
                            textDecoration: 'none'
                        }}
                    />
                </ModalHeader>               
                <ModalBody 
                    bgColor={'#4A6CAD'} 
                    textAlign={'center'} 
                    mt={'10px'}
                    onScroll={(e) => {
                        if(aoRolar){
                            aoRolar(e)}
                        }
                    }
                    paddingInline={'20px'}
                    {...estiloCorpoModal}
                >
                    {texto && 
                        <Text fontWeight='bold' fontSize={['sm', 'md']} color={'#FFF'}>
                            {texto}
                        </Text>
                    }
                    {children}
                </ModalBody>
                {((!textoBotaoSalvar || textoBotaoSalvar === '') && (!textoBotaoFechar || textoBotaoFechar === '')) ? <></> :
                    <ModalFooter bgColor={'#4A6CAD'} borderBottomLeftRadius={5}  borderBottomRightRadius={5}>
                        {textoBotaoFechar &&
                            <Button colorScheme='red' mr={2} onClick={aoFechar}>
                                {textoBotaoFechar}
                            </Button>
                        }
                        {textoBotaoSalvar &&
                            <Button ref={botaoRef} colorScheme='green' onClick={aoSalvar}>                        
                                {textoBotaoSalvar}
                            </Button>
                        }
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>        
    )

}
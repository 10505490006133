import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Center } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmbulance, faCar, faMotorcycle } from "@fortawesome/free-solid-svg-icons";
import { mdiFireTruck } from "@mdi/js";
import Icon from "@mdi/react";
import io from 'socket.io-client';
import GoogleMap from 'google-map-react';
import axios from "axios";

import { useTheme } from "styled-components";
import { useContexto } from "../../Contextos/Contexto";
import Topbar from "../../componentes/Topbar";
import ModalConfirmacao from "../../componentes/ModalConfirmacao";
import buscaEndereco from "../../funcoes/buscaEndereco";

interface DadosLocalizacaoProps {
    coordenadas: {
        latitude: number, 
        longitude: number
    }
    data: string;
    hora: string;    
    marca: string;
    modelo: string;
    statusGps: string;
    id_rastreador: number;
    modo: number;    
    tipo: number;
    velocidade: number;
}

interface CoordenadasProps {
    latitude: number;
    longitude: number;
}

interface ModalProps {
    mostrar: boolean;
    texto: string;
}

interface RetornoSocketProps {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
    10: string;
    11: string;
    12: string;
    13: string;
    14: string;
    15: string;
    16: string;
    17: string;
    18: string;
    19: string;
    20: string;
}

interface RetornoSocketNt20Props {
    alarm: number;
    batteryVoltage: number;
    cellId: number;
    courseStatus: number;
    dataTimeGps: number;
    gsm: number;
    internalDateTime: number;
    lac: number;
    latitude: number;
    lbs: number;
    locationSourceType: number;
    longitude: number;
    mcc: number;
    mileage: number;
    mnc: number;
    powerVoltage: number;
    quantitySatellites: number;
    serialNumber: number;
    speed: number;
    terminalId: string;
    totalHoursSum: number;
    terminalInformationContent: {
        bit1: string;
    }
}

export default function LocalizacaoAtual() {

    const { alterarCarregamento, usuario } = useContexto();  
    const { id } = useParams();      
    const parametros = useLocation();     
    const theme = useTheme();
    const refMapa = useRef<any>(null);
    const socket = useRef<any>(null);
    const navigate = useNavigate(); 

    const [ status, alterarStatus ] = useState(false);
    const [ dadosLocalizacao, alterarDadosLocalizacao ] = useState({} as DadosLocalizacaoProps);
    const [ modal, alterarModal ] = useState({} as ModalProps);
    const [ retornos, alterarRetornos ] = useState<RetornoSocketProps[]>([]);
    const [ retornosNt20, alterarRetornosNt20 ] = useState<RetornoSocketNt20Props[]>([]);

    // carrega o trajeto da viagem
    async function carregaLocalizacaoAtual() {

        // mostra carregamento
        alterarCarregamento({ mostrar: true, mensagem: 'Carregando veículo no mapa' });

        try {

            // faz a requisição
            const retornoTrajeto = await axios({
                url: '/veiculos/buscaLocalizacaoAtual.php',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                    id
                }
            });
            
            // verifica se o status está certo
            if(retornoTrajeto.data.status) {

                // altera os dados da localização
                alterarDadosLocalizacao(retornoTrajeto.data.dados);

            }                    

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });

        }

    }

    // função para verificar que tipo de marcador é para ser inserido no mapa
    function verificaMarcadorMapa(tipo: number, modelo: string): JSX.Element {

        // prepara marcador, setando carro como padrão
        let marcador = faCar;

        // se for ambulancia
        if(modelo == 'Ambulância' && tipo == 1) marcador = faAmbulance;        

        // se for caminhão
        if((modelo == 'Caminhão' && tipo == 3) || tipo == 3) return <Icon 
            path={mdiFireTruck}     
            style={{
                width: '100%',
                height: '100%'
            }} 
            color={theme.cores.texto}                                  
        />;

        // verifica o tipo
        switch(tipo) {

            case 1:
                if(modelo !== 'Ambulância') marcador = faCar;      
                break;
            case 2:
                marcador = faMotorcycle;
                break;
                      
        }

        // retorna o ícone com seu path
        return <FontAwesomeIcon 
            icon={marcador}
            color={theme.cores.texto}
            style={{
                width: '100%',
                height:'100%'
            }}
        />
        
    }

    // define a cor do fundo do veículo de acordo com seu modo
    function defineCorFundoVeiculo(modo: string) {

        // define cor
        let cor = theme.cores.cinza;

        // verifica modo
        switch(modo){

            // se for modo um
            case "1":        
            
                // a cor é vermelha
                cor = theme.cores.vermelho;
                break;

            case "2":
                
                // a cor é verde
                cor = theme.cores.verde;
                break;

            default:

                // a cor é cinza
                cor = theme.cores.cinza;
                break;
        }

        // retorn a cor
        return cor;

    }

    // função responsável por atualizar o veículo no mapa
    function atualizarPosicaoVeiculo(dados: RetornoSocketProps) {

        // armazena os dados vindos do socket
        alterarRetornos([...retornos, dados]);

        // prepara coordenadas
        let latitude: string = dados[7]
        let longitude: string = dados[8]        

        // verifica se possui coordenadas
        if(latitude && longitude){

            // centralisa o mapa
            let arrayCentraliza = [] as CoordenadasProps[];

            // adiciona ao array
            arrayCentraliza.push({
                latitude: parseFloat(latitude),
                longitude:parseFloat(longitude)
            })

            // centraliza no mapa
            centralizaCoordenadas(arrayCentraliza);

            // altera localização
            alterarDadosLocalizacao({
                ...dadosLocalizacao,
                coordenadas: {
                    latitude: parseFloat(latitude),
                    longitude:parseFloat(longitude)
                },
                modo: Number(dados[16]),
                tipo: dadosLocalizacao.tipo
            })            

        }        

    } 

    // função responsável por atualizar o veículo no mapa com rastreador novo
    function atualizarPosicaoVeiculoNt20(dados: RetornoSocketNt20Props) {
        
        // armazena os dados vindos do socket
        alterarRetornosNt20([...retornosNt20, dados]);

        // prepara modo
        const modo  = dados.terminalInformationContent.bit1 == '0' ? '1':'2' ;
        
         // prepara coordenadas
         let latitude: number = dados.latitude;
         let longitude: number = dados.longitude;       
 
         // verifica se possui coordenadas
         if(latitude && longitude){
 
             // centralisa o mapa
             let arrayCentraliza = [] as CoordenadasProps[];
 
             // adiciona ao array
             arrayCentraliza.push({
                 latitude,
                 longitude
             })
 
             // centraliza no mapa
             centralizaCoordenadas(arrayCentraliza);
 
             // altera localização
             alterarDadosLocalizacao({
                 ...dadosLocalizacao,
                 coordenadas: {
                    latitude,
                    longitude
                 },
                 modo: parseFloat(modo),
                 tipo: dadosLocalizacao.tipo
             })            
 
         }        
        

    }

    // função responsável por centralizar coordenadas no mapa
    function centralizaCoordenadas(coordenadas: CoordenadasProps[]) {

        // cria bounds
        let bounds = new refMapa.current.maps_.LatLngBounds();
            
        // percorre trajeto
        coordenadas.map((t) => {
            
            // adiciona coordenadas a bounds
            return bounds.extend(new refMapa.current.maps_.LatLng(t.latitude, t.longitude));
            
        })                    

        // centraliza
        refMapa.current.map_.fitBounds(bounds);                

    }
     
    // cria marcador
    const Marcador = ({ lat, lng, bg, ...props }: any) => { 

        useEffect(() => {
                
            // verifica se possui id
            if(id) {

                // recebe um sinal de que algum usuário está online ou offline
                socket.current.on(`posicaoAtualizadaNt20/${parseInt(id)}`, atualizarPosicaoVeiculoNt20);
                
                // retorna o socket para deixar desligado
                return () => {
                    socket.current.off(`posicaoAtualizadaNt20/${parseInt(id)}`, atualizarPosicaoVeiculoNt20); 
                }

            }          
                
        }, []);

        useEffect(() => {
            
            // verifica se possui id
            if(id) {

                // recebe um sinal de que algum usuário está online ou offline
                socket.current.on(`posicaoAtualizada/${parseInt(id)}`, atualizarPosicaoVeiculo);
                
                // retorna o socket para deixar desligado
                return () => {
                    socket.current.off(`posicaoAtualizada/${parseInt(id)}`, atualizarPosicaoVeiculo); 
                }       
                
            }
    
        }, []);

        return <Center 
            w={'40px'} 
            h={'40px'}
            p={'5px'} 
            borderWidth={'4px'} 
            borderColor={'black'}
            borderRadius={20}
            bg={bg}
            position={'absolute'}
            transform={'translate(-50%, -50%)'}
            onClick={async () => {

                // mostra carregamento
                alterarCarregamento({
                    mostrar: true,
                    mensagem: 'Carregando endereço'
                })

                // resgata texto
                const texto = await buscaEndereco({
                    usuario, 
                    lat, 
                    lng, 
                    alterarCarregamento
                });   

                // esconde carregamento
                alterarCarregamento({ mostrar: false });
                
                // mostra modal
                alterarModal({
                    mostrar: true,
                    texto,
                })

            }}
            _hover={{
                cursor: 'pointer'
            }}
            {...props}
        >

            {/* verifica marcador que vai no mapa */}
            {verificaMarcadorMapa(dadosLocalizacao.tipo, dadosLocalizacao.modelo)}

        </Center>
    }

    useEffect(() => {
        // carrega as viagens de determinado dia
        (async () => await carregaLocalizacaoAtual())();        
    }, []);

    // inicializa sockets
    useEffect(() => {

        // inicializa
        socket.current = io(`https://bemquetevi.com.br:7211/`, {
            transports: ['websocket'],
            secure: true
        });

        // desconectado
        return () => {

            socket.current.close();

        };
        
    }, []);

    // verifica se socket está online
    useEffect(() => {

        // define o status do socket
        socket.current.on('connect', () => alterarStatus(true));
        socket.current.on('disconnect', () => alterarStatus(false));

        // em caso de mudança do status, socket deverá ser destruido para ser reconsturido
        return () => {

            socket.current.off('connect', alterarStatus(true));
            socket.current.off('disconnect', alterarStatus(false));

        };

    }, [status, retornos, retornosNt20]);

    useEffect(() => {

        // verifica se possui referência
        if((refMapa && refMapa.current) && dadosLocalizacao.coordenadas) {
        
            // cria bounds
            let bounds = new refMapa.current.maps_.LatLngBounds();
                                        
            // adiciona coordenadas a bounds
            bounds.extend(new refMapa.current.maps_.LatLng(dadosLocalizacao.coordenadas.latitude, dadosLocalizacao.coordenadas.longitude));                            
            
            // centraliza
            refMapa.current.map_.fitBounds(bounds);

            // altera o zoom
            refMapa.current.map_.setZoom(15);

        }

    }, [refMapa, dadosLocalizacao])
    
    return(<>
        <Box flex={1} height={'100vh'} flexDirection={'column'} bg={theme.cores.fundoGeral}>
            <Topbar 
                rota={parametros.pathname} 
                navigate={navigate} 
                titulo='Localização atual' 
                subTitulo={`${parametros.state.marca || ''} ${parametros.state.modelo || ''} | Placa: ${parametros.state.placa}`}
            />
            <Flex flexGrow={1} h={'calc(100% - 70px)'} pos={'relative'}>                    
                <GoogleMap     
                    ref={refMapa}                        
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={{
                        lat: parseFloat(usuario.latitude),
                        lng: parseFloat(usuario.longitude)
                    }}
                    defaultZoom={5}
                    bootstrapURLKeys={{
                        key: usuario.chaveApiGoogle,
                        language: 'pt-br',
                        region: 'BR'                  
                    }}
                    options={{
                        scrollwheel: true,
                        fullscreenControl: true,
                        zoomControl: true,
                        minZoom: 5
                    }}  
                >
                    
                    {/* verifica se possui dados */}
                    {Object.keys(dadosLocalizacao).length > 0 && 
                        <Marcador 
                            lat={dadosLocalizacao.coordenadas.latitude}
                            lng={dadosLocalizacao.coordenadas.longitude}
                            bg={defineCorFundoVeiculo(dadosLocalizacao.modo.toString())}
                        />
                    }

                </GoogleMap>
            </Flex>
        </Box>

        {/* verifica se mostra modal */}
        {modal.mostrar && <ModalConfirmacao
            mostrar={modal.mostrar}
            texto={modal.texto}
            aoFechar={() => alterarModal({ ...modal, mostrar: false })}
            aoSalvar={() => {}}
            titulo={''}
        />}

    </>)

}
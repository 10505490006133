import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';

import Login from "../telas/Login";
import RotasHome from '../telas/Home/RotasHome';
import { useContexto } from '../Contextos/Contexto';
import { Center } from '@chakra-ui/layout';

export default function Rotas(){

    const { logado } = useContexto();    
    
    return( 

        <div style={{ minHeight: '100vh', flexDirection: 'column' }}>
            <BrowserRouter>                       
                <Routes>                                                        
                    <Route index element={<Navigate replace to={logado ? "/login" : "/home"} />} />                                                               
                    
                    { logado && 
                        <>                                
                            <Route path={'/home/*'} element={<RotasHome />} /> 
                            <Route path={'/login'} element={<Navigate to={"/home/veiculos-mapa"} replace />} /> 
                        </>
                    }

                    { !logado && 
                        <>
                            <Route path={'/login'} element={<Login /> } />   
                            <Route path={'/home/*'} element={<Navigate to={"/login"} replace />  } />
                        </>
                    }

                    <Route path={'*'} element={<Center color={'red'}>ERRO 404</Center>} />
                </Routes>
            </BrowserRouter>
        </div>        

    )

}
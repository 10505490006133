import { useRef, useState, useEffect } from 'react';
import { useContexto } from '../../Contextos/Contexto';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    Box, 
    VStack, 
    Flex, 
    Text, 
    HStack, 
    Container, 
    Checkbox, 
    Center 
} from '@chakra-ui/react';

import { useTheme } from 'styled-components';
import GoogleMap from 'google-map-react'; 
import Icon from '@mdi/react'
import { 
    mdiClockTimeNineOutline,
    mdiMapMarkerDistance,
    mdiSpeedometer,
    mdiSpeedometerMedium
} from '@mdi/js';
import axios from 'axios';

import Topbar from '../../componentes/Topbar';
import { funcaoTrataTempo } from '../../funcoes/trataTempo';
import buscaEndereco from '../../funcoes/buscaEndereco';
import ModalConfirmacao from '../../componentes/ModalConfirmacao';
import { isMobile } from 'react-device-detect';

interface ViagemSelecionadaProps {    
    df: string;
    di: string;
    dr: string;
    hf: string;
    hi: string;
    id: string;
    alt: number;
    ds: number;
    emg: number;    
    key: number;    
    vavg: number;    
    vmax: number;    
}

interface InformacoesProps {
    icone: string;
    label: string | number;
    descricao: string;
    cor?: string;
}

interface CoordenadasProps {
    latitude: number | string;
    longitude: number | string;
}

interface TrajetoProps {
    id_viagem: string;
    trajeto: CoordenadasProps[];
}

interface ModalProps {
    mostrar: boolean;
    texto: string;
}

export default function ViagemMapa() {

    const { usuario, alterarCarregamento } = useContexto();  
    const { id } = useParams();      
    const parametros = useLocation();     
    const theme = useTheme();
    const refMapa = useRef<any>(null);
    const navigate = useNavigate();      

    const [ primeiraConsulta, alterarPrimeiraConsulta ] = useState<boolean>(true);    
    const [ unirViagens, alterarUnirViagens ] = useState<boolean>(false);
    const [ loop, alterarLoop ] = useState<NodeJS.Timer>();
    const [ linhaViagem, alterarLinhaViagem ] = useState<any>();

    const [ viagemSelecionada, alterarViagemSelecionada ] = useState({} as ViagemSelecionadaProps);
    const [ modal, alterarModal ] = useState({} as ModalProps);

    const [ viagens, alterarViagens ] = useState<Array<ViagemSelecionadaProps>>([]);
    const [ informacoes, alterarInformacoes ] = useState<Array<InformacoesProps>>([]);
    const [ marcadores, alterarMarcadores ] = useState<JSX.Element[]>([]);
    const [ polylines, alterarPolylines ] = useState<any>([]);

    // retorna função aleatório
    const randomColor = () => ('#' + ((Math.random() * 0xffffff) << 0).toString(16) + 'fff000').slice(0, 7);

    // carrega as viagens de determinado dia
    async function carregaViagensDoDia() {

        // mostra o carregamento
        alterarCarregamento({ mostrar: true });

        try {            

            // faz a requisição
            const retornoViagensDia = await axios({
                url: '/viagens/buscaViagensDia.php',
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                    data: parametros.state.viagem.dt,
                    id,
                    id_principal: usuario.id_principal                                       
                }
            })

            // se veio retorno
            if(retornoViagensDia.data.status) {

                // adiciona viagens ao estado
                alterarViagens(retornoViagensDia.data.dados);     
                
                // se for a primeira vez que está consultando
                if(primeiraConsulta) {

                    // altera setando o primeiro trajeto retornado
                    alterarViagemSelecionada(retornoViagensDia.data.dados[0]);

                    // passou da primeira consulta
                    alterarPrimeiraConsulta(false);

                }                    

            }            

        } catch(e) {
            console.log(e)
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });

        }

    }

    // carrega o trajeto da viagem
    async function carregaTrajeto() {

        // se o trajeto selecionado não estiver setado, para de executar a função
        if(Object.keys(viagemSelecionada).length === 0 || !viagemSelecionada) return;

        // mostra carregamento
        alterarCarregamento({ mostrar: true, mensagem: 'Carregando itens no mapa' });

        // instancia informações com verificações
        const distancia = unirViagens || viagens.length === 1 ? parametros.state.viagem.ds : viagemSelecionada.ds;
        const duracao = unirViagens || viagens.length === 1 ? parametros.state.viagem.dr  : viagemSelecionada.dr;
        const velocidadeMedia = unirViagens || viagens.length === 1 ? parametros.state.viagem.vavg : viagemSelecionada.vavg;
        const velocidadeMaxima = unirViagens || viagens.length === 1 ? parametros.state.viagem.vmax : viagemSelecionada.vmax;

        try {

            // faz a requisição
            const retornoTrajeto = await axios({
                url: '/veiculos/buscaTrajeto2.php',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                    id: viagemSelecionada.id,
                    id_rastreador: id,
                    id_principal: usuario.id_principal,                    
                    unirTodos: unirViagens,
                    data: parametros.state.viagem.dt
                }
            })                        
                
            // altera com os dados do trajeto
            renderizaTrajetoViagem(retornoTrajeto.data);
                                             
            // gera as informações para o rodapé
            alterarInformacoes([
                {
                    icone: mdiMapMarkerDistance,
                    label: `${distancia} km`,
                    descricao: 'distância'
                },
                {
                    icone: mdiClockTimeNineOutline,
                    label: funcaoTrataTempo(duracao),
                    descricao: 'duração'
                },
                {
                    icone: mdiSpeedometerMedium,
                    label: `${velocidadeMedia} km/h`,
                    descricao: 'média'
                },
                {
                    icone: mdiSpeedometer,
                    label: `${velocidadeMaxima} km/h`,
                    descricao: 'máxima',
                    cor: Number(velocidadeMaxima) > parametros.state.veiculo.vmax ? theme.cores.vermelho : theme.cores.texto
                }
            ])              

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });

        }

    }
    
    // função para renderizar percurso de viagens
    function renderizaTrajetoViagem(data: TrajetoProps[]) {

        // cria array de componentes de marcadores
        let arrayMarcadores: JSX.Element[] = [];
        let arrayCoordenadasCentralizar: CoordenadasProps[] = [];
        
        // verifica se possui linha
        if(polylines.length > 0){
            
            // percorre e remove linha do mapa
            polylines.forEach((polyline: any) => {                
                polyline.setMap(null);
            })            

        }

        // percorre dados
        data.map((d: TrajetoProps, indice) => {

            // armazena trajetos
            const trajeto: CoordenadasProps[] = d.trajeto;

            // caso nao seja a ultima viagem da lista
            if(indice < data.length-1){

                // adiciona no final dessa o inicio da proxima
                trajeto.push({
                    latitude:Number(data[indice + 1].trajeto[0].latitude),
                    longitude:Number(data[indice + 1].trajeto[0].longitude)
                })                                

            }           

            // chama função para renderizar linha no mapa e adiciona ao array
            alterarPolylines(renderizarLinhaNoMapa(refMapa.current.map_, refMapa.current.maps_, trajeto, indice > 0 && indice < data.length));
            
            // verifica se não é o primeiro e nem o último
            if(indice > 0 && indice < data.length) {

                // instancia marcador
                arrayMarcadores.push(<Marcador 
                    key={d.id_viagem}
                    lat={trajeto[0].latitude}
                    lng={trajeto[0].longitude}
                    bg={theme.cores.cinza}
                    texto={indice}
                />)                

            }        
          
            // percorre trajeto
            trajeto.map(t => {
                arrayCoordenadasCentralizar.push({
                    latitude: Number(t.latitude),
                    longitude: Number(t.longitude)
                })
            })

        })

        // marcador do inicio A
        arrayMarcadores.push(<Marcador 
            key={'A'}
            lat={arrayCoordenadasCentralizar[0].latitude}
            lng={arrayCoordenadasCentralizar[0].longitude}
            bg={theme.cores.verde}
            texto={'A'}
        />); 

        // marcador de início B
        arrayMarcadores.push(<Marcador 
            lat={arrayCoordenadasCentralizar[arrayCoordenadasCentralizar.length -1].latitude}
            lng={arrayCoordenadasCentralizar[arrayCoordenadasCentralizar.length -1].longitude}
            bg={theme.cores.vermelho}
            texto={'B'}
            key={'B'}
        />)
        
        // centraliza no mapa
        centralizaCoordenadas(arrayCoordenadasCentralizar);
        
        // adiciona marcadores ao estado
        alterarMarcadores(arrayMarcadores);

    }

    // gera linha no mapa
    function renderizarLinhaNoMapa(map: any, maps: any, coordenadas: CoordenadasProps[], corAleatoria?: boolean) { 
        
        // verifica qual array resgata
        let arrayTrajetos: CoordenadasProps[] = coordenadas;   
        
        // verifica se pode retirar linha do mapa
        if(linhaViagem && !unirViagens) {

            // retira linha do mapa
            linhaViagem.setMap(null);
            
        }
        
        // verifica se possui ref
        if(refMapa && refMapa.current) {                    

            // gera linha
            let geodesicPolyline = new maps.Polyline({
                path: arrayTrajetos.map(t => {
                    return {
                        lat: t.latitude,
                        lng: t.longitude
                    }
                }),
                icons: [
                    {
                        offset: '100%'
                    },
                ],
                options:{
                    strokeColor: corAleatoria ? randomColor() : theme.cores.preto,
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    offset: '0%',
                }
            })
                        
            // muda no mapa
            geodesicPolyline.setMap(map)  
            
            // altera linha do mapa
            alterarLinhaViagem(geodesicPolyline);

            // retorna linha
            return geodesicPolyline;

        }                

    }

    // função responsável por centralizar coordenadas no mapa
    function centralizaCoordenadas(trajeto: CoordenadasProps[]) {

        // cria bounds
        let bounds = new refMapa.current.maps_.LatLngBounds();
            
        // percorre trajeto
        trajeto.map(t => {
            
            // adiciona coordenadas a bounds
            bounds.extend(new refMapa.current.maps_.LatLng(t.latitude, t.longitude));
            
        })            
        
        // centraliza
        refMapa.current.map_.fitBounds(bounds);

    }

    // faz animação de icone sob polyline
    function iconeLinhaAnimado() {

        let lineOffset = 0;
        let iconSpeed = 0.2;

        let lineSymbol = {
            path: refMapa.current.maps_.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 8,
            fillColor: '#566895',
            fillOpacity: 1,
            strokeColor: '#282c41',
            strokeOpacity: 1,
            strokeWeight: 2
        };
        
        //move the icon
        let loop = setInterval(() => {

            if(lineOffset > 195) {

                // finaliza interval
                clearInterval(loop);
                let lineIcon = polylines.icons;
                lineIcon[0].offset = '100%';
                lineIcon[0].icon = undefined;
                polylines.set('icons', lineIcon);                                
                alterarLoop(undefined);
                return;

            }

            lineOffset = (lineOffset + iconSpeed) % 200;
            let lineIcon = polylines.icons;
            lineIcon[0].offset = lineOffset / 2 + '%';
            lineIcon[0].icon = lineSymbol;
            polylines.set('icons', lineIcon);
        }, 20);

        alterarLoop(loop);

    }
    
    // cria marcador
    const Marcador = ({ texto, bg, lat, lng, ...props }: any) => <Center 
        w={'30px'} 
        h={'30px'} 
        borderWidth={'4px'} 
        borderColor={'black'}
        bg={bg}
        cursor={'pointer'}
        position={'absolute'}
        transform={'translate(-50%, -50%)'}
        onClick={async () => {

            // mostra carregamento
            alterarCarregamento({
                mostrar: true,
                mensagem: 'Carregando endereço'
            })

            // resgata texto
            const texto = await buscaEndereco({
                usuario, 
                lat, 
                lng, 
                alterarCarregamento
            });   

            // esconde carregamento
            alterarCarregamento({ mostrar: false });
            
            // mostra modal
            alterarModal({
                mostrar: true,
                texto,
            })

        }}
        {...props}
    >
        <Text
            isTruncated
            fontFamily={`${theme.fontes.texto}, sans-serif`}
            color={theme.cores.texto}            
        >
            {texto}
        </Text>
    </Center>;    
    
    // cria opções para ser instanciadas no mapa
    function criarOpcoesMapa(maps: any) {

        return {
            scrollwheel: true,
            fullscreenControl: true,
            zoomControl: !isMobile,
            fullscreenControlOptions: {
                position: maps.ControlPosition.BOTTOM_LEFT
            }
        }

    }

    useEffect(() => {
        // carrega as viagens de determinado dia
        (async () => await carregaViagensDoDia())();        
    }, []);

    useEffect(() => {
        // carrega o trajeto da viagem escutando mudanças do estado
        (async () => await carregaTrajeto())();         
    }, [viagemSelecionada, unirViagens]); 
    
    return(<>

        <Box flex={1} height={'100vh'} flexDirection={'column'} bg={theme.cores.fundoGeral}>
            <Topbar 
                rota={parametros.pathname} 
                navigate={navigate} 
                titulo='Minhas viagens' 
                subTitulo={`${parametros.state.veiculo.marca || ''} ${parametros.state.veiculo.modelo || ''} | Placa: ${parametros.state.veiculo.placa}`}
            />
            <Flex flexGrow={1} h={'calc(100% - 70px)'} pos={'relative'}>
                    
                <GoogleMap     
                    ref={refMapa}                        
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={{
                        lat: parseFloat(usuario.latitude),
                        lng: parseFloat(usuario.longitude)
                    }}
                    defaultZoom={5}
                    bootstrapURLKeys={{
                        key: usuario.chaveApiGoogle,
                        language: 'pt-br',
                        region: 'BR'                  
                    }}
                    options={criarOpcoesMapa}  
                >
                    
                    {/* percorre marcadores */}
                    {(marcadores && marcadores.length > 0) && marcadores.map(m => { return m})}  
                                                        
                </GoogleMap> 

                {/* verifica se tem mais de 1 viagem */}
                {viagens.length > 1 && <Container  
                    pos={'absolute'}                      
                    maxW={isMobile ? '220px' : '250px'}
                    w={isMobile ? 'auto' : '20vw'}
                    minW={'100px'}
                    top={['5px','10px']}
                    margin={'auto'}
                    left={isMobile ? '0px' : '30px'}
                    centerContent                            
                >
                    <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        borderRadius={10}
                        border={'1px solid black'}
                        p={'10px'}
                        bg={theme.cores.fundoBotaoTransparente}
                        w={'100%'}
                        flex={1}
                        overflow={'hidden'}
                        _focus={{
                            borderStyle: 'none',
                            textDecoration: 'none',
                            boxShadow:'none !important'
                        }}
                    >
                        <Checkbox
                            isTruncated
                            color={theme.cores.texto}
                            fontFamily={`${theme.fontes.titulo}, cursive`}
                            boxShadow={'none !important'}        
                            isChecked={unirViagens}
                            onChange={() => {          
                                
                                // verifica se está desabilitado
                                if(!unirViagens){
                                    
                                    // retira viagem selecionada
                                    alterarViagemSelecionada({ ...viagemSelecionada, id: '' });

                                } else {

                                    // adiciona primeira viagem como selecionada
                                    alterarViagemSelecionada(viagens[0]);

                                }               

                                // altera para valor contrário
                                alterarUnirViagens(!unirViagens);                                    

                            }}                                                       
                            alignItems={'center'}
                            justifyContent={'center'}                                
                            colorScheme={unirViagens ? theme.cores.verde : theme.cores.texto}                                
                            _hover={{
                                opacity: 0.6,
                                cursor: 'pointer',
                                boxShadow:'none !important',
                            }}
                            _active={{
                                opacity: 0.3,
                                boxShadow:'none !important',
                                borderStyle: 'none',
                                textDecoration: 'none',                                  
                            }}
                            _focus={{
                                borderStyle: 'none',
                                textDecoration: 'none',
                                boxShadow:'none !important',                                    
                            }}
                            size={isMobile ? 'sm' : 'md'}
                        >
                            Unir todas as viagens
                        </Checkbox>
                    </Flex>
                </Container>}

                {/* verifica se possui viagens */}
                {(viagens && viagens.length > 0) &&
                    <Container 
                        pos={'absolute'}                      
                        maxW={'700px'}
                        w={isMobile ? '100vw' : '60vw'}
                        minW={'200px'}
                        top={isMobile ? '55px' : '10px'}
                        right={0}
                        left={0}
                        margin={'auto'}
                        centerContent                                 
                    >                            
                        <Flex
                            border={'1px solid black'}                      
                            w={'100%'} 
                            h={'100%'} 
                            borderRadius={10}
                            bg={theme.cores.fundoBotaoTransparente}
                            alignItems={'center'}                         
                            justifyContent={'center'}  
                            color={theme.cores.texto}
                            fontFamily={`${theme.fontes.texto}, sans-serif`}                                    
                        >                       
                            <Box 
                                maxW={'600px'}
                                paddingInline={'5px'}
                                alignItems={'center'} 
                                justify={'center'}
                                overflow={'auto'} 
                                borderRadius={10}
                                p={'10px'}
                                display={'inline-flex'}
                                css={{
                                    '&::-webkit-scrollbar': {
                                        height: '8px'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: theme.cores.preto,
                                        borderRadius: '10px',
                                    }
                                }}
                            >
                                
                                {/* percorre informações */}
                                {viagens.map((viagem, indice) => <VStack paddingInline={'10px'} flex={1} key={indice} color={(!unirViagens && viagem.id === viagemSelecionada.id) ? theme.cores.texto : theme.cores.preto}>
                                    
                                    <VStack
                                        borderRadius={50}   
                                        w={isMobile ? '65px' : '80px'}
                                        h={isMobile ? '65px' : '80px'}
                                        align={'center'}
                                        justify={'center'}
                                        border={'1px solid black'}                      
                                        bg={(!unirViagens && viagem.id === viagemSelecionada.id) ? theme.cores.topoTopBar : theme.cores.texto}
                                        spacing={'-2px'}
                                        onClick={() => {

                                            // verifica se foi pressionado a mesma viagem
                                            if(viagem.id === viagemSelecionada.id) return;
                                            alterarUnirViagens(false);
                                            alterarViagemSelecionada(viagem);

                                        }}
                                        _hover={{
                                            opacity: 0.6,
                                            cursor: 'pointer'
                                        }}
                                        _active={{
                                            opacity: 0.3
                                        }}
                                    >
                                        <Text 
                                            isTruncated
                                            fontSize={isMobile ? 'md' : 'lg'}
                                            fontFamily={`${theme.fontes.titulo}, cursive`}
                                        >
                                            {viagem.ds}
                                        </Text>
                                        <Text 
                                            isTruncated
                                            fontSize={isMobile ? 'xs    ' :'sm'}                                                
                                        >
                                            km
                                        </Text>
                                    </VStack>
                                    <Text 
                                        isTruncated 
                                        color={theme.cores.texto}
                                        fontSize={isMobile ? 'sm' : 'md'}
                                    >
                                        {funcaoTrataTempo(viagem.hi)}
                                    </Text>
                                </VStack>)}

                            </Box>                                
                        </Flex>
                    </Container>
                }

                {/* verifica se possui informacações */}
                {(informacoes && informacoes.length > 0) &&                       
                    <Container 
                        pos={'absolute'}                      
                        maxW={'700px'}
                        w={isMobile ? '100%' : '60vw'}
                        minW={'200px'}
                        bottom={'10px'}
                        right={0}
                        left={0}
                        margin={'auto'}
                        centerContent                    
                    >
                        <Flex
                            border={'1px solid black'}                        
                            w={'100%'} 
                            h={'100%'} 
                            borderRadius={10}
                            alignItems={'center'}                         
                            justifyContent={'center'}  
                            color={theme.cores.texto}
                            direction={'column'}
                            fontFamily={`${theme.fontes.texto}, sans-serif`}
                        >
                            <HStack 
                                p={'5px'}
                                w={'100%'}
                                alignItems={'center'} 
                                justify={'space-around'}
                                bg={theme.cores.topoTopBar} 
                                borderTopRadius={10}                               
                            >                                   
                                <Text 
                                    isTruncated
                                    fontSize={isMobile ? 'sm' : 'md'}
                                >
                                    {viagemSelecionada.di}
                                </Text>         
                                
                                {/* se estiver unido, só mostra a data */}
                                {!unirViagens && <> 
                                    <Text 
                                        isTruncated
                                        fontSize={isMobile ? 'sm' : 'md'}
                                    >
                                        {parametros.state.viagem.ds} km
                                    </Text>
                                    <Text 
                                        isTruncated
                                        fontSize={isMobile ? 'sm' : 'md'}
                                    >
                                        viagem {viagemSelecionada.key} de {viagens.length}
                                    </Text>
                                </>}

                            </HStack>
                            <HStack 
                                flex={1} 
                                w={'100%'}
                                alignItems={'center'} 
                                justify={'center'}
                                bg={theme.cores.fundoBotaoTransparente}
                                borderBottomRadius={10}
                                p={'5px'}
                            >
                                
                                {/* percorre informações */}
                                {informacoes.map((info, indice) => <VStack w={'100%'} flex={1} key={indice} color={theme.cores.texto} spacing={'-2px'}>
                                    <Icon 
                                        path={info.icone}        
                                        size={isMobile ? '25px' : '40px'}                                    
                                    />
                                    <Text 
                                        isTruncated 
                                        fontSize={isMobile ? 'md'  :'xl'} 
                                        color={info.cor ? info.cor : theme.cores.texto}
                                    >
                                        {info.label}
                                    </Text>
                                    <Text 
                                        isTruncated 
                                        fontSize={isMobile ? 'xs' :'sm'}                                    
                                    >
                                        {info.descricao}
                                    </Text>
                                </VStack>)}

                            </HStack>
                        </Flex>
                    </Container>          
                }
                
                <Container  
                    pos={'absolute'}                      
                    maxW={isMobile ? '220px' : '250px'}
                    w={isMobile ? 'auto' : '20vw'}
                    minW={'100px'}
                    top={isMobile ? '5px' : '10px'}
                    margin={'auto'}
                    right={isMobile ? '0px' : '30px'}
                    centerContent                            
                >
                    <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        borderRadius={10}
                        border={'1px solid black'}
                        p={'10px'}
                        bg={theme.cores.fundoBotaoTransparente}
                        w={'100%'}
                        flex={1}
                        overflow={'hidden'}
                    >
                        <Flex
                            justify={'center'}
                            align={'center'}
                            onClick={() => {
                                
                                // verifica se está percorrendo trajeto
                                if(loop) {
                                    clearInterval(loop);
                                    let lineIcon = polylines.icons;
                                    lineIcon[0].offset = '100%';
                                    lineIcon[0].icon = undefined;
                                    polylines.set('icons', lineIcon);
                                    alterarLoop(undefined);
                                } else {
                                    iconeLinhaAnimado();
                                }

                            }}
                            _hover={{
                                opacity: 0.6,
                                cursor: 'pointer',
                                boxShadow:'none !important',
                            }}
                            _active={{
                                opacity: 0.3,
                                boxShadow:'none !important',
                                borderStyle: 'none',
                                textDecoration: 'none',                                  
                            }}
                            _focus={{
                                borderStyle: 'none',
                                textDecoration: 'none',
                                boxShadow:'none !important'
                            }}
                        >
                            <FontAwesomeIcon 
                                icon={loop ? faStop : faPlay}
                                color={'white'}
                                style={{
                                    width: '20px',
                                    height:'20px',
                                    marginRight: '5px'
                                }}
                            />
                            <Text
                                isTruncated
                                fontFamily={`${theme.fontes.texto}, sans-serif`}
                                color={theme.cores.texto}            
                            >
                                {loop ? 'Finalizar trajeto' : 'Iniciar Trajeto'}
                            </Text>
                        </Flex>
                    </Flex>
                </Container>
            </Flex>             
        </Box>
 
        {/* verifica se mostra modal */}
        {modal.mostrar && <ModalConfirmacao
            mostrar={modal.mostrar}
            texto={modal.texto}
            aoFechar={() => alterarModal({ ...modal, mostrar: false })}
            aoSalvar={() => {}}
            titulo={''}
        />}

    </>)

} 
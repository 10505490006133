import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Text, Divider, Flex, VStack } from '@chakra-ui/layout';
import { IoMdAlert } from "react-icons/io";
import moment from 'moment';

import Lista from '../../componentes/Lista';
import Topbar from '../../componentes/Topbar';
import { ModalDataProps } from '../../interfaces';
import ModalConfirmacao from '../../componentes/ModalConfirmacao';
import { funcaoTrataTempo } from '../../funcoes/trataTempo';
import { funcaoRetornaErroAlerta } from '../../funcoes/funcaoRetornaErroAlerta';
import { useContexto } from '../../Contextos/Contexto';
import { isMobile } from 'react-device-detect';

interface NotificacaoProps {
    comando: string;
    data: string;
    hora: string;
    id: string;
    idAlerta: string;
    visto: string;
    velocidade: string;    
}

export default function Notificacoes() {

    const { id } = useParams();
    const parametros = useLocation();
    const { alterarCarregamento } = useContexto();
    
    const theme = useTheme();          
    const navigate = useNavigate();     

    const meses = [
        'Janeiro', 
        'Fevereiro', 
        'Março', 
        'Abril', 
        'Maio', 
        'Junho', 
        'Julho', 
        'Agosto', 
        'Setembro', 
        'Outubro', 
        'Novembro', 
        'Dezembro'
    ];

    const [ carregouDados, alterarCarregouDados ] = useState<boolean>(false);
    const [ pagina, alterarPagina ] = useState<number>(1);
    const [ paginaAlertaDia, alterarPaginaAlertaDia ] = useState<number>(1);
    const [ dataNotificacao, alterarDataNotificao ] = useState<string>('');    
    const [ filtroData, alterarFiltroData ] = useState<string>('0000-00-00');
    const [ placeholderFiltroData, alterarPlaceholderFiltroData ] = useState<string>('');
    const [ notificacoes, alterarNotificacoes ] = useState([]);         
    const [ modalData, alterarModalData ] = useState({
        mostrar: false,
        titulo: 'Escolha uma data disponível',
        textoBotaoSalvar: 'Confirmar'
    } as ModalDataProps);    
    const [ modalConfirmacao, alterarModalConfirmacao ] = useState({
        mostrar: false,
        titulo: '',
        registros: []
    });
   
    // função que carrega todos os alertas recebidos
    async function carregaTodosAlertas() {

        // mostra carregamento
        alterarCarregamento({ mostrar: true });   

        // instancia placeholder
        let placeholder = '';
        
        try {                                       
            
            // faz a requisição
            const retornoTodosAlertas = await axios({
                url: '/alertas/buscaTodosAlertas.php',
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data:{
                    id,
                    paginaAtual: pagina,
                    quantidade: 15,
                    busca: '',
                    filtroData
                }
            })

            // verifica se veio retorno
            if(retornoTodosAlertas.data.status){                                      

                // altera array
                alterarNotificacoes(retornoTodosAlertas.data.dados);
                                
            } else {
                alterarNotificacoes([]);
                alterarPagina(1);
            }
            
            // verifica data
            if(filtroData === '' || filtroData === '0000-00-00') {

                // altera placeholder da data
                alterarPlaceholderFiltroData('Filtrar por data');                  

            } else {

                const divide = filtroData.split("-");
                const dia = divide[2]
                const mes = divide[1]
                const ano = divide[0]

                // verifica se possui dia
                if(dia !== '00'){
                    // intera o placeholder
                    placeholder += `${dia} de `
                }
    
                // verifica se possui mês
                if(mes !== '00'){
                    // intera o placeholder
                    placeholder += `${meses[Number(mes)-1]} de `
                }
    
                // verifica se possui ano
                if(ano !== '0000'){
                    // intera o placeholder
                    placeholder += `${ano}`
                }
                
                // altera placeholder data
                alterarPlaceholderFiltroData(placeholder);            

            }     
            
            // alterar pra ver se ja carregou todos
            alterarCarregouDados(retornoTodosAlertas.data.fim);

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });
        
        }

    } 
    
    // função que carrega os alertas recebidos por data
    async function carregaAlertaPorDia() {

        // se não estiver mostrando o modal, não consulta
        if(!dataNotificacao || dataNotificacao === '') return;

        // mostra carregamento
        alterarCarregamento({ mostrar: true });   
        
        try {                                       
            
            // faz a requisição
            const retornoAlertaPorDia = await axios({
                url: '/alertas/buscaAlertasDia.php',
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data:{
                    id,
                    paginaAtual: paginaAlertaDia,
                    quantidade: 16,
                    busca: '',
                    data: dataNotificacao,
                    filtroData: "0000-00-00"
                }
            })

            // verifica se veio retorno
            if(retornoAlertaPorDia.data.status){                                      

                // altera array
                alterarModalConfirmacao({
                    mostrar: true,
                    titulo: moment(dataNotificacao).format('DD/MM/YYYY'),
                    registros: modalConfirmacao.registros.concat(retornoAlertaPorDia.data.dados)
                })
                                
            }
                                     
            // alterar pra ver se ja carregou todos
            alterarCarregouDados(retornoAlertaPorDia.data.fim);

        } catch(e) {
            console.log(e);
        } finally {

            // esconde carregamento
            alterarCarregamento({ mostrar: false });
        
        }

    }
    
    // cria children do topbar da lista
    const ChildrenTopbarLista = () => {
        return(<>           
            <Text   
                mt={'4px'}    
                fontFamily={`${theme.fontes.titulo}, cursive`} 
                fontSize={['xs', 'sm', 'md']}
                color={theme.cores.texto}
                textAlign={'center'}
                isTruncated                                     
            >
                {parametros.state.marca || ''} {parametros.state.modelo || ''}
            </Text>                                                                                                   
            {!isMobile && <Divider orientation='vertical' />}
            <Text                                                  
                fontFamily={`${theme.fontes.texto}, sans-serif`} 
                fontSize={['xs', 'sm', 'md']}
                color={theme.cores.texto}
                textAlign={'center'}
                flexDirection={'row'}                       
                isTruncated
            >
                Placa: {parametros.state.placa}
            </Text>
        </>)
    }

    useEffect(() => {
        // carrega função novamente
        carregaTodosAlertas();
    }, [pagina, filtroData])

    useEffect(() => {
        // chama função de acordo com mudanças no efeito
        carregaAlertaPorDia();
    }, [paginaAlertaDia, dataNotificacao])
    
    return(<>
       <Flex w={'100%'} minH={'100vh'} h={'100%'} direction={'column'} bg={'transparent'} overflow={'auto'}>        
            <Topbar rota={parametros.pathname} titulo={'Alertas e emergências'} navigate={navigate} />                    
            <Lista 
                pagina={pagina}
                modalData={modalData}
                alterarModalData={alterarModalData}
                alterarPagina={alterarPagina}
                dados={notificacoes}
                placeholderFiltro={placeholderFiltroData}
                alterarFiltroData={alterarFiltroData}        
                childTopbarLista={<ChildrenTopbarLista />}            
                estiloContainerLista={{
                    maxHeight: 550
                }}
                estiloContainer={{
                    p: '10px'
                }}    
                tipoLista={'notificacao'}  
                registroClicado={(notificacao) => alterarDataNotificao(notificacao.dt)}       
                textoPrincipal={(dado) => `${dado.nv} ${dado.nv === '1'? 'notificação' :'notificações'}`}
                textoSecundario={(dado) => `Alertas: ${dado.alt}`}  
                labelTooltip={(dado) => moment(dado.dt).format('DD/MM/YYYY')}
            />
        </Flex>

        <ModalConfirmacao
            titulo={modalConfirmacao.titulo}
            mostrar={modalConfirmacao.mostrar}
            aoFechar={() => {                
                alterarModalConfirmacao({...modalConfirmacao, mostrar: false, registros: []})                
                alterarPaginaAlertaDia(1);
                alterarDataNotificao('');
            }}
            aoSalvar={() => {}}
            estiloCorpoModal={{
                overflow: 'auto',
                maxH: 500
            }}
            aoRolar={(e: any) => {

                // verifica se chegou ao fim do scroll e chama a função
                const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

                // verifica se scroll chegou ao fim e se permite carregar mais dados
                if(bottom && !carregouDados) {

                    // altera paginação
                    alterarPaginaAlertaDia(paginaAlertaDia + 1);
                    
                }

            }}
        >

            {/* verifica se possui registros e se o modal está aparecendo */}
            {(modalConfirmacao.registros.length > 0 && modalConfirmacao.mostrar) ? modalConfirmacao.registros.map((registro: NotificacaoProps, indice: number) => <Flex 
                key={indice}
                alignItems={'center'} 
                justifyContent={'flex-start'} 
                flexDirection={'row'}
                p={'10px' }
                bg={theme.cores.topoTopBar}
                borderRadius={'10px'}
                margin={'auto'}                
                mt={'10px'}
                mb={'10px'}
            >
                <Flex
                    bg={registro.comando.substring(5, 8) === 'ALT' ? theme.cores.alerta : theme.cores.vermelho}
                    w={50}
                    h={50}
                    borderRadius={50}
                    align={'center'}
                    justifyContent={'center'}
                >
                    <IoMdAlert
                        color={registro.comando.substring(5, 8) === 'ALT' ? theme.cores.preto : theme.cores.texto}
                        size={35}
                    />
                </Flex>
                <Flex
                    flex={1}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    ml={'10px'}                    
                >
                    <VStack
                        alignItems={'flex-start'}
                        justifyContent={'center'}
                        spacing={'-5px'}
                    >                        
                        <Text
                            color={theme.cores.texto}
                            fontFamily={`${theme.fontes.texto}, sans-serif`}
                            fontSize={'lg'}
                            textAlign={'left'}
                            isTruncated
                        >
                            {/* chama função para tratar a hora */}
                            {funcaoTrataTempo(registro.hora)}
                        </Text>
                        <Text
                            color={theme.cores.cinza}
                            textAlign={'left'}
                            fontFamily={`${theme.fontes.texto}, sans-serif`}
                            isTruncated
                        >
                            {/* chama função para verificar que erro é para ser retornado */}
                            {funcaoRetornaErroAlerta(registro.comando, registro.idAlerta, registro.velocidade)}
                        </Text>
                    </VStack>
                </Flex>
            </Flex>) : <></>}  

        </ModalConfirmacao>

    </>) 

}
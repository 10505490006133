import { ReactChild, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faChevronLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';
import { NavigateFunction } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { useContexto } from "../../Contextos/Contexto";
import ModalConfirmacao from '../ModalConfirmacao';
import CarroAdicao from '../svgr/CarroAdicao';
import '../../telas/Login/loginEstilo.scss';
import { Button, Flex, Avatar, Text, Tooltip, VStack } from '@chakra-ui/react';

interface ModalProps {
    mostrar: boolean;
    titulo?: string;
    children?: ReactChild;
    texto?: string;
    textoBotaoFechar: string;
    textoBotaoSalvar: string;
    aoSalvar: () => void;
}

interface TopbarProps {
    navigate: NavigateFunction;    
    rota:string;
    titulo?:string;   
    subTitulo?: string; 
    veiculoClicado?: () => void;
}

export default function Topbar({
    rota,
    titulo,
    subTitulo,
    veiculoClicado,
    navigate    
}: TopbarProps){

    const { alterarLogado, usuario } = useContexto();
    const [ modal, alterarModal ] = useState({
        mostrar: false,
        titulo: '',
        texto: '',
        textoBotaoFechar: '',
        textoBotaoSalvar: ''
    } as ModalProps);        

    const theme = useTheme(); 
    
    // desloga do sistema
    function logOut() {

        // desloga
        alterarLogado(false);

        // limpa o cache
        localStorage.clear();                    

    }    
    
    return (<>
        <Flex  
            w={'100%'}
            h={'70px'}
            bg={theme.cores.topoTopBar}
            color={theme.cores.texto}
            align={'center'}
            justify={'space-between'}
            p={'20px'}
        >            
            <Flex w={'auto'} p={['5px', '20px']} align={'center'} justify={'center'} left={5}>
                {rota === '/home' 
                    ? 
                        <Flex>
                            <Avatar 
                                src={`https://www.bemquetevi.com.br/api/imagens/${usuario.imagem}`}
                                size={'md'}
                            />
                            <Text
                                fontFamily={`${theme.fontes.texto}, sans-serif`}
                                color={theme.cores.texto}
                                fontSize={isMobile ? 'md' : 'xl'}
                                isTruncated
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                maxW={isMobile ? '200px' : 'auto'}
                                ml={5}
                            >
                                Bem vindo, {usuario.nome || ''} {usuario.sobrenome || ''}
                            </Text>
                        </Flex>
                    : 
                        <Tooltip label={'Voltar'} aria-label='Voltar'>
                            <Button 
                                onClick={() => navigate(-1)}
                                bg={'none'}
                                display={'flex'}                                        
                                mr={'10px'}
                                borderStyle={'none'}
                                p={'0px'}
                                w={isMobile ? '20px' : '30px'}
                                h={isMobile ? '20px' : '30px'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                cursor={'pointer'}
                                _active={{
                                    opacity: 0.5
                                }}            
                                _hover={{
                                    opacity: 0.8
                                }}
                                _focus={{
                                    borderStyle: 'none',
                                    textDecoration: 'none'
                                }}
                            >
                                <FontAwesomeIcon 
                                    icon={faChevronLeft} 
                                    color={'#FFF'} 
                                    style={{ cursor: 'pointer', height: '100%', width: '100%' }}                                    
                                />
                            </Button>
                        </Tooltip>
                }
            </Flex>
            <VStack
                justifyContent={'center'}
                alignItems={'center'}                
                textAlign={'center'}
                flex={1}
                spacing={['0px', '-2px']}
            > 
                <Text
                    fontFamily={`${theme.fontes.titulo}, sans-serif`}
                    color={theme.cores.texto}
                    fontSize={['md', 'lg', 'xl', '2xl']}
                    isTruncated={!isMobile}
                    w={'100%'}                    
                    maxW={'auto'}
                >
                    {titulo}
                </Text>
                {subTitulo &&
                    <Text
                        fontFamily={`${theme.fontes.titulo}, sans-serif`}
                        color={theme.cores.texto}
                        fontSize={['xs', 'sm', 'md', 'lg']}
                        isTruncated={!isMobile}
                        maxW={['50%', '100%']}         
                    >
                        {subTitulo}
                    </Text>
                }
            </VStack>
            <Flex 
                w={'auto'} 
                p={['5px', '20px']} 
                align={'center'} 
                justify={'center'} 
                right={5}
            >
                <Tooltip label={'Voltar para a página inicial'} aria-label='Voltar para página início'>
                    <Button 
                        onClick={() => navigate('/home')}
                        bg={'none'}
                        display={'flex'}                                        
                        mr={'10px'}
                        borderStyle={'none'}
                        p={'0px'}
                        w={['25px', '32px']}
                        h={['25px', '32px']}
                        justifyContent={'center'}
                        alignItems={'center'}
                        cursor={'pointer'}
                        _active={{
                            opacity: 0.5
                        }}            
                        _hover={{
                            opacity: 0.8
                        }}
                        _focus={{
                            borderStyle: 'none',
                            textDecoration: 'none'
                        }}
                        _pressed={{
                            opacity: 0.5
                        }}
                    >   
                        <FontAwesomeIcon 
                            icon={faHome} 
                            color={'#FFF'}
                            style={{ width: '100%', height: '100%' }}                                                 
                        />
                    </Button> 
                </Tooltip>
                {/* {rota === '/home' &&
                    <Tooltip label={'Cadastrar veículo'} aria-label='Cadastrar veículo'>                        
                        <Button 
                            onClick={() => veiculoClicado ? veiculoClicado() : () => {}}
                            bg={'none'}
                            display={'flex'}                                        
                            mr={'10px'}
                            borderStyle={'none'}
                            p={'0px'}
                            w={'35px'}
                            h={'35px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            cursor={'pointer'}
                            _active={{
                                opacity: 0.5
                            }}            
                            _hover={{
                                opacity: 0.8
                            }}
                            _focus={{
                                borderStyle: 'none',
                                textDecoration: 'none'
                            }}
                        >   
                            <CarroAdicao 
                                width={'100%'} 
                                height={'100%'} 
                                style={{ color: '#FFF' }}
                            />
                        </Button> 
                    </Tooltip>  
                } */}
                <Tooltip label={'Sair'} aria-label='Sair'>
                    <Button 
                        onClick={() => alterarModal({
                            mostrar: true,
                            titulo: 'Log Out',
                            texto: 'Você deseja encerrar a sessão?',
                            textoBotaoFechar: 'Cancelar',
                            textoBotaoSalvar: 'Sim',
                            aoSalvar: () => logOut()
                        })}
                        bg={'none'}
                        display={'flex'}                                        
                        mr={['0px', '10px']}
                        borderStyle={'none'}
                        p={'0px'}
                        w={['28px', '35px']}
                        h={['28px', '35px']}
                        justifyContent={'center'}
                        alignItems={'center'}
                        cursor={'pointer'}
                        _active={{
                            opacity: 0.5
                        }}            
                        _hover={{
                            opacity: 0.8
                        }}
                        _focus={{
                            borderStyle: 'none',
                            textDecoration: 'none'
                        }}
                    >
                        <FontAwesomeIcon 
                            icon={faSignOutAlt} 
                            color={'#FFF'}
                            style={{ width: '100%', height: '100%' }}                                                 
                        />
                    </Button>    
                </Tooltip>                    
            </Flex>
        </Flex>        

        <ModalConfirmacao
            mostrar={modal.mostrar}
            texto={modal.texto}
            aoFechar={() => alterarModal({ ...modal, mostrar: false })}
            aoSalvar={modal.aoSalvar}
            titulo={modal.titulo || ''}
            textoBotaoFechar={modal.textoBotaoFechar}
            textoBotaoSalvar={modal.textoBotaoSalvar}
        />

    </>)

}